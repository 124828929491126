import { Sector } from "recharts";

const renderActiveShape = (count) => (props) => {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } =
    props;

  return (
    <g>
      <text
        x={cx}
        y={cy}
        dy={-8}
        className="text-[38px] font-bold"
        textAnchor="middle"
        fill="#06152B"
      >
        {count}
      </text>
      <text
        className="text-[15px]"
        x={cx}
        y={cy}
        dy={28}
        textAnchor="middle"
        fill="#06152B"
      >
        Recommendations
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </g>
  );
};

export default renderActiveShape;
