import React from "react";
import { Outlet } from "react-router-dom";
import Menu from "./Menu/Menu";
import Navbar from "./Navbar/Navbar";

const Layout = () => {
    return (
      <div className="main flex h-[100vh]">
        <Menu className="bg-[#F9F9F9]" />

        <div className="container md:px-[31px] px-[20px] overflow-y-auto md:max-w-full">
          <div className="menuContainer">
            <Navbar />
          </div>
          <div className="contentContainer">
            <Outlet />
          </div>
        </div>
      </div>
    );
  };

  export default Layout;