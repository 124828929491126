import React from "react";

const UserProfile = ({ userPicture, userName, labels }) => {
  return (
    <div className="flex">
      <div className="w-[40px] h-auto">
        <img
          src={userPicture}
          alt="profile"
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "0.25rem",
            objectFit: "cover",
          }}
        />
      </div>
      <div className="ml-[15px]">
        <h6 className="text-[14px] text-[#2A2A2A] font-semibold">{userName}</h6>
        <p className="text-[#9197B3] text-[10px]">{labels["settings_owner"]}</p>
      </div>
    </div>
  );
};

export default UserProfile;
