import React, { useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { Fragment } from "react";
import Icons from "../../components/Common/Icons";
import Note from "../../components/Common/Note";
import InsightCard from "../../components/Common/InsightCard/InsightCard";
import { GetGoals } from "../../services/goal.service";
import {
  GetRecommendations,
  GetTypes,
  UpdateRecommendationStatus,
} from "../../services/recommendation.service";
import { useActiveStore } from "../../contexts/ActiveStore";
import { useAuth } from "../../utils/user";
import { useNavigate } from "react-router";
import { useActiveItem } from "../../contexts/ActiveTabContext";

const Recommendation = () => {
  const [goals, setGoals] = useState([{ name: "All" }]);
  const [types, setTypes] = useState([{ type: "All" }]);
  const [recommendations, setRecommendations] = useState([]);
  const [loading, setLoading] = useState(false);
  const { activeStore } = useActiveStore();
  const { handleItemClick } = useActiveItem();
  const { authUser, labels, icons } = useAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;
  const [isLoadingAuthUser, setIsLoadingAuthUser] = useState(true);
  const [isAuthUserChecked, setIsAuthUserChecked] = useState(false);

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const fetchData = async () => {
    setLoading(true);
    const goalsResponse = await GetGoals();
    const typesResponse = await GetTypes();
    setGoals((prevGoals) => [...prevGoals, ...goalsResponse?.data?.data]);
    setTypes((prevTypes) => [...prevTypes, ...typesResponse?.data?.data]);
    if (activeStore) {
      const recommendationsResponse = await GetRecommendations(activeStore.id);
      if (
        recommendationsResponse &&
        recommendationsResponse.data &&
        recommendationsResponse.data.data
      ) {
        const filteredRecommendations =
          recommendationsResponse.data.data.filter(
            (item) => item.status === "Open"
          );
        setRecommendations(filteredRecommendations);
      } else {
        // Handle the case where there is no data or an error
        setRecommendations([]);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    // Define a timeout duration (e.g., 5000 milliseconds)
    const timeoutDuration = 2000;

    // Set up a timeout to wait for authUser
    const timeout = setTimeout(() => {
      setIsAuthUserChecked(true); // Indicates the check is complete
    }, timeoutDuration);

    return () => clearTimeout(timeout); // Clear the timeout if the component unmounts
  }, []); // Empty dependency array to run only once after mount

  useEffect(() => {
    handleItemClick("item2");
    if (isAuthUserChecked) {
      setIsLoadingAuthUser(false);
      if (!authUser || !authUser.stores || authUser.stores.length < 1) {
        navigate("/settings");
      }

      fetchData();
    }
  }, [activeStore, isAuthUserChecked, authUser]);

  const [selectedGoal, setSelectedGoal] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");

  // Find the goal ID corresponding to the selected goal
  const selectedGoalId = selectedGoal
    ? goals.find((goal) => goal.name === selectedGoal)?.id
    : null;

  // Find the type ID corresponding to the selected type
  const selectedTypeId = selectedType
    ? types.find((type) => capitalizeFirstLetter(type.type) === selectedType)
        ?.id
    : null;

  const implementRecommendation = async (id) => {
    const response = await UpdateRecommendationStatus(
      id,
      "Implement",
      new Date()
    );

    if (response.data.data) {
      fetchData();
    }
  };

  if (isLoading) {
    return "Loading ...";
  }

  const totalPages = Math.ceil(recommendations.length / itemsPerPage);

  return (
    <div>
      <div className="md:flex justify-between items-center">
        <h4 className="text-[20px] text-[#2A2A2A] font-medium">
          {labels["recommendations_all_insights"]}
        </h4>
        <div className="flex items-center flex-wrap">
          <div className="flex h-10 items-center border border-[#9197B3] w-[220px]  rounded-[4px] mx-[12.5px]">
            <span className="justify-start px-[4px] text-[#9197B3] text-[14px] items-center">
              Goal:
            </span>
            <Listbox value={selectedGoal} onChange={setSelectedGoal}>
              <div className="relative flex-1">
                <Listbox.Button className="text-[#157810] h-10 text-[14px] font-medium relative w-full cursor-pointer rounded-lg text-left">
                  <span className="block truncate ">{selectedGoal}</span>
                  <span className="bg-[#157810] text-[#fff] pointer-events-none rounded-r-[3px] px-[3px] absolute inset-y-0 right-0 flex items-center ">
                    <Icons iconName="icon-arrow-up" />
                  </span>
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute mt-1 max-h-60 z-[9] w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {goals.map((goal, index) => (
                      <Listbox.Option
                        key={index}
                        className={({ active }) =>
                          `relative cursor-pointer select-none py-2 px-2 ${
                            active
                              ? "bg-amber-100 text-[#157810]"
                              : "text-gray-900"
                          }`
                        }
                        value={goal.name}
                      >
                        {({ selected }) => (
                          <>
                            <span
                              className={`block ${
                                selected ? "font-normal" : "font-normal"
                              }`}
                            >
                              {goal.name}
                            </span>
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
          </div>
          {/* <div className="flex h-10 items-center border border-[#9197B3] w-[220px]  rounded-[4px] mx-[12.5px]">
            <span className="justify-start px-[4px] text-[#9197B3] text-[14px] items-center">
              Status:
            </span>
            <Listbox value={selectedStatus.name} onChange={setSelectedStatus}>
              <div className="relative  flex-1">
                <Listbox.Button className="text-[#157810] h-10 text-[14px] font-medium relative w-full cursor-pointer rounded-lg text-left">
                  <span className="block truncate ">{selectedStatus.name}</span>
                  <span className="bg-[#157810] text-[#fff] pointer-events-none rounded-r-[3px] px-[3px] absolute inset-y-0 right-0 flex items-center ">
                    <Icons iconName="icon-arrow-up" />
                  </span>
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute mt-1 max-h-60 z-[9] w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {status.map((stat, statIdx) => (
                      <Listbox.Option
                        key={statIdx}
                        className={({ active }) =>
                          `relative cursor-pointer select-none py-2 px-5 ${
                            active
                              ? "bg-amber-100 text-[#157810]"
                              : "text-gray-900"
                          }`
                        }
                        value={stat}
                      >
                        {({ selected }) => (
                          <>
                            <span
                              className={`block ${
                                selected ? "font-medium" : "font-normal"
                              }`}
                            >
                              {stat.name}
                            </span>
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
          </div> */}
          <div className="flex h-10 items-center border border-[#9197B3] w-[220px]  rounded-[4px] mx-[12.5px]">
            <span className="justify-start px-[4px] text-[#9197B3] text-[14px] items-center">
              Type:
            </span>
            <Listbox value={selectedType} onChange={setSelectedType}>
              <div className="relative  flex-1">
                <Listbox.Button className="text-[#157810] h-10 text-[14px] font-medium relative w-full cursor-pointer rounded-lg text-left">
                  <span className="block truncate ">{selectedType}</span>
                  <span className="bg-[#157810] text-[#fff] pointer-events-none rounded-r-[3px] px-[3px] absolute inset-y-0 right-0 flex items-center ">
                    {/* <img src={chevronDown} alt="chevron" /> */}
                    <Icons iconName="icon-arrow-up" />
                  </span>
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute mt-1 max-h-60 z-[9] w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {types.map((type, personIdx) => (
                      <Listbox.Option
                        key={personIdx}
                        className={({ active }) =>
                          `relative cursor-pointer select-none py-2 px-5 ${
                            active
                              ? "bg-amber-100 text-[#157810]"
                              : "text-gray-900"
                          }`
                        }
                        value={capitalizeFirstLetter(type.type)}
                      >
                        {({ selectedType }) => (
                          <>
                            <span
                              className={`block ${
                                selectedType ? "font-normal" : "font-normal"
                              }`}
                            >
                              {capitalizeFirstLetter(type.type)}
                            </span>
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
          </div>

          <button
            type="button"
            onClick={() => {
              setSelectedGoal("");
              setSelectedType("");
              setSelectedStatus("");
            }}
            className="btn flex py-[9px] px-[10px] items-center border-[#157810] border text-[14px] font-semibold rounded-[4px] text-[#157810]"
          >
            Reset Filter
          </button>
        </div>
      </div>
      <div className="my-[30px]">
        <Note
          Icon="icon-info-alert"
          title="How to Use"
          desc="Here you can find your unique actionable insights & recommendations based on Thruways’ performance analysis of your store. Follow the recommendations to increase your optimization score and boost sales."
        />
      </div>
      <div className="flex justify-between flex-wrap">
        {recommendations &&
          recommendations
            .filter((recommendation) => {
              // Filter based on selectedGoal if it's not empty
              if (
                selectedGoalId !== null &&
                recommendation.recommendation_category?.goal_id !==
                  selectedGoalId &&
                selectedGoal !== "All"
              ) {
                return false;
              }

              // Filter based on selectedType if it's not empty
              if (
                selectedTypeId !== null &&
                recommendation.recommendation_type_id !== selectedTypeId &&
                selectedType !== "All"
              ) {
                return false;
              }

              // Filter based on selectedStatus if it's not empty
              // if (
              //   selectedStatus !== "" &&
              //   recommendation.status !== selectedStatus.name
              //   && selectedStatus  !== 'All'
              // ) {
              //   return false;
              // }

              return true;
            })
            .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
            .map((recommendation, index) => (
              <InsightCard
                key={index}
                headTitle={recommendation.recommendation_category?.goal.name}
                headType={recommendation.recommendation_type?.background_color}
                headIcon={recommendation.type === 'Issue' ? icons['issue_white.svg'] : recommendation.type === 'Warning' ?  icons['warning_white.svg'] : icons['opportunity_white.svg']}
                headTitleRight={recommendation.type}
                cardTitle={recommendation.description}
                cardDescExplaination={recommendation.explanation}
                cardDescRecomAct={recommendation.recommended_action}
                impactpercent={recommendation.expected_impact}
                metrics={recommendation.recommendation_metrics}
                id={recommendation.id}
                howToImplement={recommendation.how_to_implement}
                implementRecommendation={implementRecommendation}
              />
            ))}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "10px",
          marginTop: "20px",
        }}
      >
        {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
          <button
            key={page}
            onClick={() => setCurrentPage(page)}
            style={{
              fontWeight: currentPage === page ? "bold" : "normal",
              backgroundColor: currentPage === page ? "#4CAF50" : "#e7e7e7", // Active page in different color
              color: currentPage === page ? "white" : "black",
              border: "none",
              padding: "8px 16px",
              textAlign: "center",
              textDecoration: "none",
              display: "inline-block",
              fontSize: "16px",
              margin: "4px 2px",
              borderRadius: "5px",
              cursor: "pointer",
              transition: "background-color 0.3s",
            }}
            onMouseOver={(e) => (e.target.style.backgroundColor = "#45a049")} // Change color on hover
            onMouseOut={(e) =>
              (e.target.style.backgroundColor =
                currentPage === page ? "#4CAF50" : "#e7e7e7")
            }
          >
            {page}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Recommendation;
