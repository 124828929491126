import { useLocation, useNavigate} from "react-router-dom";
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useEffect } from "react";


const PrivateRoute = ({ component, ...args }) => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    // Check if the current route is '/'
    if (location.pathname === '/') {
      navigate("/dashboard");
    }
  }, [location, navigate]);
  const Component = withAuthenticationRequired(component, args);
  return <Component />;
};

export default PrivateRoute;
