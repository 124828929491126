import React, { useState, useEffect } from "react";
import { Listbox, Transition } from "@headlessui/react";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Icons from "../../components/Common/Icons";
import { Fragment } from "react";
import { GetIndustries } from "../../services/industry.service";
import { useActiveStore } from "../../contexts/ActiveStore";
import { useAuth } from "../../utils/user";
import { UpdateAccount } from "../../services/account.service";
import FieldLabel from "../Common/FieldLabel";

const BusinessInfoForm = () => {
  const { authUser, labels, setAuthUser } = useAuth();
  const { activeStore } = useActiveStore();
  const [selectedIndustry, setSelectedIndustry] = useState("");
  const [uploadSuccess, setUploadSuccess] = useState(null);
  const [industries, setIndustries] = useState([]);
  const [initialValues, setInitialValues] = useState({
    businessName: activeStore?.name || "",
    url: activeStore?.domain_url || "",
    phone: activeStore?.phone || "",
    industry: "",
    accountId: "",
  });

  useEffect(() => {
    fetchIndustries();
  }, [authUser]);

  useEffect(() => {
    let timer;

    if (uploadSuccess) {
      timer = setTimeout(() => {
        setUploadSuccess(null);
      }, 4000); // 4000 milliseconds = 4 seconds
    }

    // Clear the timer if the component unmounts or if uploadSuccess changes again
    return () => clearTimeout(timer);
  }, [uploadSuccess]); // This effect runs every time imageSuccess changes

  useEffect(() => {
    if (activeStore && activeStore.industry_id && industries.length > 0) {
      setInitialValues({
        businessName: activeStore.name || "",
        url: activeStore.domain_url || "",
        phone: activeStore.phone || "",
        industry: activeStore.industry_id || "",
        storeId: activeStore.id || "",
        accountId: activeStore.account_id || "",
      });
      // Find the industry object
      const selectedIndustry = industries.find(
        (industry) => industry.id === activeStore.industry_id
      );

      // Set the found industry object as the selected industry
      setSelectedIndustry(selectedIndustry);
    }
  }, [activeStore, authUser, industries]);

  const fetchIndustries = async () => {
    const industries = await GetIndustries();

    setIndustries(industries?.data?.data);
  };

  const validationSchema = Yup.object({
    businessName: Yup.string().required("Please enter your Business name"),
    phone: Yup.number().required("Please enter your Business Phone number"),
    url: Yup.string().required("Please enter your Business URL"),
  });

  const onSubmit = async (values) => {
    const paylaod = {
      name: values.businessName,
      phone: values.phone,
      url: values.url,
      industry_id: selectedIndustry.id,
      store_id: initialValues.storeId,
      account_id: initialValues.accountId,
    };
    const response = await UpdateAccount(paylaod);
    if (response) {
      setAuthUser((prevValues) => {
        // Map through the stores array to find and update the specific store
        const updatedStores = prevValues.stores.map((store) => {
          if (store.id === activeStore.id) {
            // If it's the store we want to update, create a new object with updated fields
            return {
              ...store, // Spread the existing store fields
              name: values.businessName, // Update name
              phone: values.phone, // Update phone
              domain_url: values.url, // Update domain_url
              industry_id: selectedIndustry.id, // Update industry_id
            };
          }
          return store; // For all other stores, return them as they are
        });

        // Return the updated authUser object with the updated stores array
        return {
          ...prevValues, // Keep the rest of the authUser fields
          stores: updatedStores, // Insert the updated stores array
        };
      });

      setUploadSuccess("Updated Successfully");
    }
  };

  const handleSubmit = (values, { resetForm }) => {
    onSubmit(values);
    resetForm();
  };

  const renderError = (message) => (
    <p className="text-[#ff0000] text-[12px]">{message}</p>
  );

  const renderSuccessMsg = (message) => (
    <div
      className="flex items-center justify-start bg-green-100 border-l-4 border-green-500 text-green-700 p-4"
      role="alert"
    >
      <svg
        className="fill-current w-4 h-4 mr-2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <path d="M10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10S15.523 0 10 0zM8 15l-5-5 1.41-1.41L8 12.17l7.59-7.59L17 6l-9 9z" />
      </svg>
      <p className="font-bold">{message}</p>
    </div>
  );
  return (
    <div
      id="businessinfo"
      className="bg-[#fff] mb-[24px] shadow-[0px_20px_27px_0_rgba(0,0,0,5%)] rounded-[16px] px-[24px] py-[16px]"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        <Form>
          <h2 className="text-[#2A2A2A] text-[20px] font-semibold pb-[32px] pt-[24px]">
            {labels?.settings_business_information}
          </h2>
          <div className="grid grid-cols-2 gap-6">
            <div className="inputFormGroup">
              <FieldLabel label="Business Name" htmlFor="businessName" />
              <div className="flex items-end">
                <Field
                  name="businessName"
                  id="businessName"
                  type="text"
                  className="input bg-transparent border border-[#D2D6DA] text-[14px] rounded-[8px] focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="Thruways"
                />
              </div>
              <ErrorMessage name="businessName" render={renderError} />
            </div>
            <div className="inputFormGroup">
              <FieldLabel label="Business Phone" htmlFor="phone" />
              <div className="flex items-end">
                <Field
                  name="phone"
                  id="phone"
                  type="text"
                  className="input bg-transparent border border-[#D2D6DA] text-[14px] rounded-[8px] focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder=""
                />
              </div>
              <ErrorMessage name="phone" render={renderError} />
            </div>
            <div className="inputFormGroup">
              <FieldLabel label="Business URL" htmlFor="url" />
              <div className="flex items-end">
                <Field
                  name="url"
                  id="url"
                  type="text"
                  className="input bg-transparent border border-[#D2D6DA] text-[14px] rounded-[8px] focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="thruways.co"
                />
              </div>
              <ErrorMessage name="url" render={renderError} />
            </div>
            <div className="inputFormGroup">
              <FieldLabel label="Industry" htmlFor="industry" />
              <div className="flex items-end">
                <Listbox
                  value={selectedIndustry}
                  onChange={setSelectedIndustry}
                >
                  <div id="industry" className="relative  flex-1">
                    <Listbox.Button
                      aria-label="select industry"
                      className="text-black border-[#D2D6DA] border rounded-[8px] py-[9px] text-[16px]  relative w-full cursor-pointer p-2.5  text-left"
                    >
                      <span className="block truncate ">
                        {selectedIndustry?.name}
                      </span>
                      <span className="bg-transparent text-[12px] border-l text-[#D2D6DA] border-[#D2D6DA] pointer-events-none  px-[3px] absolute inset-y-0 right-0 flex items-center ">
                        <Icons iconName="icon-arrow-up" />
                      </span>
                    </Listbox.Button>
                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {industries.map((industry, industryIdx) => (
                          <Listbox.Option
                            key={industryIdx}
                            className={({ active }) =>
                              `relative cursor-pointer select-none py-2 px-5 ${
                                active
                                  ? "bg-amber-100 text-amber-900"
                                  : "text-gray-900"
                              }`
                            }
                            value={industry}
                          >
                            {({ selectedIndustry }) => (
                              <>
                                <span
                                  className={`block ${
                                    selectedIndustry
                                      ? "font-medium"
                                      : "font-normal"
                                  }`}
                                >
                                  {industry.name}
                                </span>
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </Listbox>
              </div>
            </div>
          </div>
          <div className="flex justify-end">
            {uploadSuccess && renderSuccessMsg(uploadSuccess)}
            <button
              type="submit"
              className="capitalize text-[12px] py-[12px] mt-[25px] text-[#ffffff] font-bold rounded-[8px] bg-[#157810] lg:w-[125px] capitalize"
            >
              {labels["settings_save_business_info"]}
            </button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default BusinessInfoForm;
