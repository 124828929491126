import React from "react";
import AnalyticsCardHeader from "./AnalyticsCardHeader";
import AnalyticsValueIndicator from "./AnalyticsValueIndicator";

const AnalyticsCard = ({ totalInc, cardIcon, title, desc, totalSale }) => {
  return (
    <div className="flex-1 bg-[#F3F8F3] px-[15px] py-[20px] md:mx-[5px] md:mb-0 mb-[10px] rounded-[8px]">
      <AnalyticsCardHeader icon={cardIcon} title={title} />
      <p className="mt-[10px] py-[5px] text-[#9197B3] text-[12px] font-normal items-center flex">
        {desc}
      </p>
      <div className="flex">
        <h2 className="text-[#2A2A2A] text-[28px] font-bold leading-[1]">
          {totalSale}
        </h2>
        <AnalyticsValueIndicator value={totalInc} />
        {/* <p className='self-end text-red-500 text-[20px] font-normal px-[10px]'>{props.totalInc}</p> */}
      </div>
    </div>
  );
};

export default AnalyticsCard;
