import React from "react";
import { useNavigate } from "react-router-dom";

const Button = ({
  implementRecommendation,
  recId,
  btnLink,
  btntitle,
  btnwidth,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (implementRecommendation) {
      implementRecommendation(recId);
    }
    if (btnLink) {
      navigate(btnLink);
    }
  };

  return (
    <button
      type="button"
      onClick={handleClick}
      className={` ${
        btnwidth ? "w-[120px] " : "md:w-[210px] w-full"
      } capitalize   font-[600] text-[#fff] bg-[#157810] text-[14px] rounder-[5px] p-[10px] rounded-[8px]`}
    >
      {btntitle}
    </button>
  );
};

export default Button;
