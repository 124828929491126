import React from "react";

const OutlineBadge = ({ title}) => {
  return (
    <div className="text-[#157810] mb-[5px] w-auto text-[10px] border border-[#157810] rounded-[30px] px-[10px] py-[2px]">
      {title}
    </div>
  );
};

export default OutlineBadge;
