import React from "react";
import {Link} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";
import { useAuth } from "../../utils/user";

function LogoutButton() {
  const {isAuthenticated, logout} = useAuth0();
  const {labels, icons} = useAuth();

  return (
    isAuthenticated && (
      <Link
        onClick={() => {
          localStorage.removeItem("token")
          localStorage.removeItem("active_shop")
          localStorage.removeItem(`auth0.${process.env.REACT_APP_AUTH0_CLIENT_ID}`)
          logout({
            logoutParams: {
              returnTo: window.location.origin
            }
          });
        }}
        to="/"
        className="flex lg:justify-start justify-center capitalize items-center bg-[#f9f9f9] z-[]"
      >
        <img src={icons['logout-icon.svg']} alt="logout" />
        <span className="px-[15px] lg:block hidden">{labels['menu_logout']}</span>
      </Link>
    )
  );
}

export default LogoutButton;
