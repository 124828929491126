const InsightCardHeader = ({
  color,
  icons,
  headTitle,
  headIcon,
  headTitleRight,
}) => (
  <div
    style={{ backgroundColor: color }}
    className="py-[5px] px-[20px] insight-header rounded-t-[14px] flex justify-between items-center"
  >
    <div className="flex items-center">
      <img src={icons["insight.svg"]} alt="Insight" />
      <p className="text-[#FEFEFF]">{headTitle}</p>
    </div>
    <div className="">
      <div className="flex items-center">
        <img src={headIcon ?? icons["info-alert.svg"]} alt="Icon" />
        <p className="pl-[10px] text-[14px] text-[#fff]">{headTitleRight}</p>
      </div>
    </div>
  </div>
);

export default InsightCardHeader;
