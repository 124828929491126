export const BASE_URL = process.env.REACT_APP_API_URL;

export const apiUrl = {
    account: 'account/user',
    updateAccount: 'account/update',
    login: 'auth/login',
    register: 'auth/signup',
    update_user: 'auth/update',
    validate_user: 'auth/validate_user',
    orderStats: 'dashboard/order-stats',
    analytics: 'order/analytics',
    industry: 'industry',
    label: 'label',
    icon: 'asset',
    optimization: 'optimization',
    goal: 'goal',
    associateGoals: 'goal/associate',
    store: 'store',
    onboarding: 'onboarding',
    recommendationTypes: 'recommendation_type',
    getRecommendations: 'recommendation/store',
    putRecommendation: 'recommendation/status',
}