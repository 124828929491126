import React from "react";
import { Link } from "react-router-dom";

const SetupSideBar = ({ step }) => {
  return (
    <div className="col-span-3 bg-[#157810] text-white pt-28 px-8 relative">
      <h4 className="text-xl mb-14 font-bold">Quick & Easy Setup</h4>
      <ol className="relative border-l ms-6 border-[#6198DE] dark:border-gray-700">
        <li className="mb-10 ml-6">
          <div
            className={`absolute rounded-full dark:border-gray-900 dark:bg-gray-700 ${
              step >= 1
                ? "w-3 h-3 bg-gray-200 -left-1.5 border border-white"
                : "w-2 h-2 bg-[#6198DE] -left-1 border border-[#6198DE]"
            }`}
          ></div>
          <div
            className={`mb-1 text-sm cursor-pointer font-normal leading-none ${
              step == 1
                ? "dark:text-white-500"
                : "text-[#6198DE] dark:text-gray-500"
            } `}
          >
            Signup
          </div>
        </li>
        <li className="mb-10 ml-6">
          <div
            className={`absolute rounded-full dark:border-gray-900 dark:bg-gray-700 ${
              step >= 2
                ? "w-3 h-3 bg-gray-200 -left-1.5 border border-white"
                : "w-2 h-2 bg-[#6198DE] -left-1 border border-[#6198DE]"
            }`}
          ></div>
          <div
            className={`mb-1 text-sm cursor-pointer font-normal leading-none ${
              step == 2
                ? "dark:text-white-500"
                : "text-[#6198DE] dark:text-gray-500"
            } `}
          >
            Connect Platform
          </div>
        </li>
        <li className="mb-10 ml-6">
          <div
            className={`absolute rounded-full dark:border-gray-900 dark:bg-gray-700 ${
              step >= 3
                ? "w-3 h-3 bg-gray-200 -left-1.5 border border-white"
                : "w-2 h-2 bg-[#6198DE] -left-1 border border-[#6198DE]"
            }`}
          ></div>
          <div
            className={`mb-1 text-sm cursor-pointer font-normal leading-none ${
              step == 3
                ? "dark:text-white-500"
                : "text-[#6198DE] dark:text-gray-500"
            } `}
          >
            Basic Information
          </div>
        </li>
        <li className="ml-6">
          <div
            className={`absolute rounded-full dark:border-gray-900 dark:bg-gray-700 ${
              step == 4
                ? "w-3 h-3 bg-gray-200 -left-1.5 border border-white"
                : "w-2 h-2 bg-[#6198DE] -left-1 border border-[#6198DE]"
            }`}
          ></div>
          <div
            className={`mb-1 text-sm cursor-pointer font-normal leading-none ${
              step == 4
                ? "dark:text-white-500"
                : "text-[#6198DE] dark:text-gray-500"
            } `}
          >
            Optimization setup
          </div>
        </li>
      </ol>
      <p className="absolute left-2 bottom-2 text-white text-sm font-normal">
        Need help?{" "}
        <Link to={"#"} className="underline decoration-solid">
          Connect with support
        </Link>
      </p>
    </div>
    //     <div className="col-span-3 bg-[#157810] text-white pt-28 px-8 relative">
    //     <h4 className="text-xl mb-14 font-bold">Quick & Easy Setup</h4>
    //     <ol class="relative border-l ms-6 border-[#6198DE] dark:border-gray-700">
    //       <li class="mb-10 ml-6">
    //         <div class="absolute w-3 h-3 bg-gray-200 rounded-full  -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
    //         <div class="mb-1 text-sm cursor-pointer font-normal leading-none  dark:text-white-500">
    //           Signup
    //         </div>
    //       </li>
    //       <li class="mb-10 ml-6">
    //         <div class="absolute w-2 h-2 bg-[#6198DE] rounded-full  -left-1 border border-[#6198DE] dark:border-gray-900 dark:bg-gray-700"></div>
    //         <div class="mb-1 text-sm cursor-pointer font-normal leading-none text-[#6198DE] dark:text-gray-500">
    //           Connect Platform
    //         </div>
    //       </li>
    //       <li class="mb-10 ml-6">
    //         <div class="absolute w-2 h-2 bg-[#6198DE] rounded-full  -left-1 border border-[#6198DE] dark:border-gray-900 dark:bg-gray-700"></div>
    //         <div class="mb-1 text-sm cursor-pointer font-normal leading-none text-[#6198DE] dark:text-gray-500">
    //           Basic Information
    //         </div>
    //       </li>
    //       <li class="ml-6">
    //         <div class="absolute w-2 h-2 bg-[#6198DE] rounded-full  -left-1 border border-[#6198DE] dark:border-gray-900 dark:bg-gray-700"></div>
    //         <div class="mb-1 text-sm cursor-pointer font-normal leading-none text-[#6198DE] dark:text-gray-500">
    //           Optimization setup
    //         </div>
    //       </li>
    //     </ol>
    //     <p className="absolute left-2 bottom-2 text-white text-sm font-normal">
    //       Need help?{" "}
    //       <Link to={"#"} className="underline decoration-solid">
    //         Connect with support
    //       </Link>
    //     </p>
    //   </div>
  );
};

export default SetupSideBar;
