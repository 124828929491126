import React, { createContext, useState, useContext } from 'react';

const ActiveItemContext = createContext();

export const useActiveItem = () => useContext(ActiveItemContext);

export const ActiveItemProvider = ({ children }) => {
  const [activeItem, setActiveItem] = useState(null);

  const handleItemClick = (item) => {
    setActiveItem(item);
  };

  return (
    <ActiveItemContext.Provider value={{ activeItem, handleItemClick }}>
      {children}
    </ActiveItemContext.Provider>
  );
};
