import React, { useState } from "react";
import { useAuth } from "../../../utils/user";
import InsightCardHeader from "./InsightCardHeader";
import CardFrontContent from "./CardFrontContent";
import CardBackContent from "./CardBackContent";

const InsightCard = ({
  headType,
  headTitle,
  headIcon,
  headTitleRight,
  cardTitle,
  cardDescExplaination,
  cardDescRecomAct,
  impactpercent,
  implementRecommendation,
  id,
  metrics,
  howToImplement,
}) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const { labels, icons } = useAuth();
  const color = headType;
  const handleButtonClick = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <div className="flip-card h-[310px] mb-[30px] md:w-[48%] w-full text-[#157810]">
      <div
        style={{ borderColor: color }}
        className={`border rounded-[16px] flip-card-inner ${
          isFlipped ? "rotateY" : ""
        }`}
      >
        {!isFlipped && (
          <div className="flip-card-front">
            <InsightCardHeader
              color={headType}
              icons={icons}
              headTitle={headTitle}
              headIcon={headIcon}
              headTitleRight={headTitleRight}
            />
            <CardFrontContent
              color={color}
              cardTitle={cardTitle}
              cardDescExplaination={cardDescExplaination}
              cardDescRecomAct={cardDescRecomAct}
              impactpercent={impactpercent}
              metrics={metrics}
              labels={labels}
              handleButtonClick={handleButtonClick}
            />
          </div>
        )}
        {isFlipped && (
          <div className={`flip-card-back`}>
            <InsightCardHeader
              color={headType}
              icons={icons}
              headTitle={headTitle}
              headIcon={headIcon}
              headTitleRight={headTitleRight}
            />
            <CardBackContent
              color={color}
              cardTitle={cardTitle}
              howToImplement={howToImplement}
              labels={labels}
              handleButtonClick={handleButtonClick}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default InsightCard;
