import {apiUrl} from "../utils/constants";
import {GET, POST} from "./api.service.wrapper";

export const GetGoals = async () => {
  const response = await GET(apiUrl.goal);
  return response;
};


export const associateGoals = async (shop_url, goal_ids) => {
  const response = await POST(apiUrl.associateGoals, {shop_url, goal_ids});
  return response;
};