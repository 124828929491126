import React from "react";
import Button from "../Button";
import OutlineBadge from "../Badge/OutlineBadge";

const CardFrontContent = ({
  color,
  cardTitle,
  cardDescExplaination,
  cardDescRecomAct,
  impactpercent,
  metrics,
  labels,
  handleButtonClick,
}) => (
  <div className="cardbody px-[20px] py-[15px]">
    <h5 className="text-[22px] font-medium">{cardTitle}</h5>
    <div className="lg:flex justify-between">
      <div className="md:pr-[25px] w-[65%] border-r-[1px] border-[#8ABC88]">
        <div className="mt-[10px] mb-[20px]">
          <h5 className=" text-[14px] font-semibold">Explanation</h5>
          <p className=" text-[12px] font-regular">{cardDescExplaination}</p>
        </div>
        <div className="mb-[20px]">
          <h5 className="text-[14px] font-semibold">Recommended Action</h5>
          <p className="text-[12px] font-regular">{cardDescRecomAct}</p>
        </div>
        {/* <div className="flex justify-end">
                    <Button
                      btnwidth="100px"
                      btntitle="Implement"
                      implementRecommendation={implementRecommendation}
                      recId={id}
                    />
                  </div> */}
      </div>
      <div className="md:ps-[25px] text-center lg:mt-[0] mt-[15px] w-[35%]">
        <h6 className="text-[#157810] text-[15px] font-semibold">
          Expected Impact
        </h6>
        <h3 className={`percent-color text-[36px] font-bold `}>
          {impactpercent}
        </h3>
        <div className="mt-[8px] h-16 items-start overflow-auto flex gap-1 flex-wrap scrollbar">
          {metrics &&
            metrics.map((metric, index) => (
              <OutlineBadge title={metric.metric_category.sub_category} />
            ))}
        </div>
        <div className="flex justify-end">
          <button
            type="button"
            onClick={handleButtonClick}
            className={`w-full capitalize font-[600] text-[#fff] bg-[#157810] text-[12px] rounder-[5px] p-[6px] rounded-[8px]`}
          >
            {labels["recommendations_how_to_implement"]}
          </button>
        </div>
      </div>
    </div>
  </div>
);

export default CardFrontContent;