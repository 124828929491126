import React, { useCallback } from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

/**
 * Wraps the application in an Auth0Provider to manage authentication,
 * with a custom redirect callback to navigate using React Router.
 */
const Auth0ProviderWithRedirectCallback = ({ children, ...props }) => {
  const navigate = useNavigate();

  // Define the redirect callback to navigate to the current path.
  const onRedirectCallback = useCallback(
    (appState) => {
      navigate(appState?.returnTo || window.location.pathname);
    },
    [navigate]
  );
  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithRedirectCallback;
