import React, { useMemo } from "react";
import Icons from "../Icons";

const OverviewValueIndicator = ({ value }) => {
  const [color, iconName] = useMemo(() => {
    return value.includes("-")
      ? ["red", "icon-arrow-down"]
      : ["green", "icon-arrow-up"];
  }, [value]);
  return (
    <p style={{ color }} className="self-center flex text-[12px]  px-[10px]">
      <span
        className={`${
          value.includes("-")
            ? "inset-y-0 right-0 flex items-center"
            : "inline-flex text-[13px] font-bold rotate-[180deg]"
        }`}
      >
        <Icons iconName={`${iconName} font-bold px-[5px]`} />
      </span>
      {value}
    </p>
  );
};

export default OverviewValueIndicator;
