import React from "react";
import GaugeComponent from "react-gauge-component";

function GuageChart({ optimizationScore }) {
  return (
    <div className="GuageChart">
      <GaugeComponent
        arc={{
          width: 0.1,
          colorArray: ["#157811"],
          cornerRadius: 1,
          padding: 0.003,
          subArcs: [
            {
              limit: 25,
              colorArray: "#5c5c5c63",
              showTick: true,
            },
            {
              limit: 50,
              colorArray: "#5c5c5c63",
              showTick: true,
            },
            {
              limit: 75,
              colorArray: "#5c5c5c63",
              showTick: true,
            },
            {
              limit: 100,
              colorArray: "red",
              showTick: true,
            },
          ],
          valueLabel: {
            fontSize: 40,
            formatTextValue: (value) => `${value}%%`,
          },
        }}
        value={optimizationScore}
      />
    </div>
  );
}

export default GuageChart;
