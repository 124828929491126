import React, { useEffect, useState, useCallback, Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { Link, useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";

import Icons from "../../components/Common/Icons";
import { GetIndustries } from "../../services/industry.service";
import { saveBasicInfo } from "../../services/onboarding.service";
import { useAuth } from "../../utils/user";
import SetupSideBar from "../../components/SetupSideBar";

const StepThree = () => {
  const navigate = useNavigate();
  const [selectedIndustry, setSelectedIndustry] = useState(null);
  const [industries, setIndustries] = useState([{ name: "None" }]);
  const [initialValues, setInitialValues] = useState({});
  const [loading, setLoading] = useState(false);
  const [industryError, setIndustryError] = useState(false);
  const { authUser, labels } = useAuth();

  useEffect(() => {
    localStorage.setItem("next-step", "step-three");

    fetchData();
  }, []);

  useEffect(() => {
    const initialValues = {
      firstName: authUser?.first_name || "", // Use given_name or a similar field from Auth0
      lastName: authUser?.last_name || "", // Use family_name or a similar field from Auth0
      companyName: "", // Replace with your actual company name field
      phoneNumber: authUser?.phone_number || "", // Replace with your actual phone number field
    };

    setInitialValues(initialValues);
  }, [authUser]);

  const fetchData = useCallback(
    debounce(async () => {
      setLoading(true);
      const response = await GetIndustries();
      setSelectedIndustry(industries[0]);
      setIndustries((prevIndustries) => [
        ...prevIndustries,
        ...response?.data?.data,
      ]);
      setLoading(false);
    }, 500),
    []
  );

  const handleIndustryChange = (industry) => {
    setSelectedIndustry(industry);
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required("Please enter your first name"),
    lastName: Yup.string().required("Please enter your last name"),
    companyName: Yup.string().required("Please enter your company name"),
    phoneNumber: Yup.string().required("Please enter your phone number"),
  });

  const handleSubmit = async (data, { resetForm }) => {
    if (selectedIndustry) {
      if (!selectedIndustry.id) {
        setIndustryError("Industry field is required");
        return;
      }
      data.industry = selectedIndustry?.id;
    }
    if (localStorage.getItem("shop"))
      data.shop = JSON.parse(localStorage.getItem("shop"))?.shop;
    const updatedData = {
      industry_id: data?.industry,
      user_id: authUser?.id,
      name: `${data?.companyName}`,
      company_name: data?.companyName,
      first_name: data?.firstName,
      last_name: data?.lastName,
      phone: data?.phoneNumber,
      shop: data?.shop,
    };
    const response = await saveBasicInfo(updatedData);
    if (response?.status) {
      localStorage.setItem("next-step", "step-four");
      navigate("/step-four");
    }
    // onSubmit(data);
    // resetForm();
  };

  const renderError = (message) => (
    <p className="text-[#ff0000] text-[12px]">{message}</p>
  );

  return (
    <div className="grid grid-cols-12 h-[100vh]">
      <div className="col-span-9 px-16 pt-28 relative">
        <h1 className="text-5xl font-semibold text-[#111] mb-4">
          {labels["step3_some_basic_information"]}
        </h1>
        <p className="text-xl mb-16 font-light text-[#1E1E1E]">
          {labels["step3_enter_some_basic_information"]}
        </p>
        <div className="grid grid-cols-2 gap-6">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            <Form>
              <div className="inputFormGroup flex space-x-4">
                <div className="w-1/2">
                  <label
                    htmlFor="firstName"
                    className="block mb-2 font-bold text-[14px] leading-[24px] text-[#4D4D4D] capitalize"
                  >
                    First Name
                  </label>
                  <div className="control">
                    <Field
                      name="firstName"
                      id="firstName"
                      type="text"
                      className="input bg-transparent border border-[#D2D6DA] text-[14px] rounded-[8px] focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      placeholder="Alec"
                    />
                    <ErrorMessage name="firstName" render={renderError} />
                  </div>
                </div>
                <div className="w-1/2">
                  <label
                    htmlFor="lastName"
                    className="block mb-2 font-bold text-[14px] leading-[24px] text-[#4D4D4D] capitalize"
                  >
                    Last Name
                  </label>
                  <div className="control">
                    <Field
                      name="lastName"
                      id="lastName"
                      type="text"
                      className="bg-transparent border border-[#D2D6DA] text-[14px] rounded-[8px] focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      placeholder="Thompson"
                    />
                    <ErrorMessage name="lastName" render={renderError} />
                  </div>
                </div>
              </div>
              <div className="inputFormGroup flex space-x-4">
                <div className="w-1/2">
                  <label
                    htmlFor="company"
                    className="block mb-2 font-bold text-[14px] leading-[24px] text-[#4D4D4D] capitalize"
                  >
                    Company Name
                  </label>
                  <div className="control">
                    <Field
                      name="companyName"
                      id="companyName"
                      type="text"
                      className="bg-transparent border border-[#D2D6DA] text-[14px] rounded-[8px] focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      placeholder="Company Name"
                    />
                    <ErrorMessage name="companyName" render={renderError} />
                  </div>
                </div>
                <div className="w-1/2">
                  <label
                    htmlFor="phonenumber"
                    className="block mb-2 font-bold text-[14px] leading-[24px] text-[#4D4D4D] capitalize"
                  >
                    Phone Number
                  </label>
                  <div className="control">
                    <Field
                      name="phoneNumber"
                      id="phoneNumber"
                      type="text"
                      onInput={(e) => {
                        e.currentTarget.value = e.currentTarget.value.replace(
                          /[^0-9]/g,
                          ""
                        );
                      }}
                      //   inputMode="numeric" // Add this line
                      //   pattern="[0-9]*"
                      className="input bg-transparent border border-[#D2D6DA] text-[14px] rounded-[8px] focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      placeholder="40 735 631 620"
                    />
                    <ErrorMessage name="phoneNumber" render={renderError} />
                  </div>
                </div>
              </div>
              <div className="inputFormGroup">
                <label
                  htmlFor="industry"
                  className="block mb-2 font-bold text-[14px] leading-[24px] text-[#4D4D4D] capitalize"
                >
                  Industry
                </label>
                <div className="flex items-end">
                  <Listbox
                    value={selectedIndustry}
                    onChange={handleIndustryChange}
                  >
                    <div
                      name="industry"
                      id="industry"
                      className="relative  flex-1"
                    >
                      <Listbox.Button className="text-black border-[#D2D6DA] border rounded-[8px] py-[9px] text-[16px]  relative w-full cursor-pointer p-2.5  text-left">
                        <span className="block truncate ">
                          {selectedIndustry?.name}
                        </span>
                        <span className="bg-transparent text-[12px] border-l text-[#D2D6DA] border-[#D2D6DA] pointer-events-none  px-[3px] absolute inset-y-0 right-0 flex items-center ">
                          <Icons iconName="icon-arrow-up" />
                        </span>
                      </Listbox.Button>
                      <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {industries?.map((industry, industryIdx) => (
                            <Listbox.Option
                              key={industryIdx}
                              className={({ active }) =>
                                `relative cursor-pointer select-none py-2 px-5 ${
                                  active
                                    ? "bg-amber-100 text-amber-900"
                                    : "text-gray-900"
                                }`
                              }
                              value={industry}
                            >
                              {({ selected }) => (
                                <>
                                  <span
                                    className={`block ${
                                      selected ? "font-medium" : "font-normal"
                                    }`}
                                  >
                                    {industry?.name}
                                  </span>
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </Listbox>
                </div>
                {industryError && renderError(industryError)}
              </div>
              <div className="flex justify-end mt-20">
                <button
                  type="submit"
                  className="text-[12px] py-[12px] mt-[25px] text-[#ffffff] font-bold rounded-[8px] bg-[#157810] lg:w-[125px] capitalize"
                >
                  {labels["step3_next"]}
                </button>
                {/* <Link to={"/step-four"}>
            <Button btntitle="NEXT" />
          </Link> */}
              </div>
            </Form>
          </Formik>
        </div>
        {/*  */}
        <p className="absolute left-2 bottom-2 text-[#A3AED0] text-sm font-medium">
          © 2023 Thruways Co. All Rights Reserved.{" "}
        </p>
      </div>
      <SetupSideBar step={3} />
    </div>
  );
};

export default StepThree;
