import React, { PureComponent } from "react";
import { AreaChart, Area, XAxis, YAxis, Tooltip } from "recharts";
import { mapSalesToChartData } from "../../../utils/utils";

export default class SalesChart extends PureComponent {
  render() {
    const { sales, last6MonthNames, isHome } = this.props;

    const data = mapSalesToChartData(sales, last6MonthNames);

    return (
      <div className="area-chart" width="100%" height="100%">
        <AreaChart
          width={isHome ? 500 : 750}
          height={250}
          data={data}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
        >
          <defs>
            <linearGradient id="gradientSales" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#10786E" stopOpacity={0.2} />
              <stop offset="95%" stopColor="#10786E" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="gradientOrders" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#fff" stopOpacity={0.6} />
              <stop offset="95%" stopColor="#fff" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis dataKey="name" />
          <YAxis dataKey="sales" />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="sales"
            stroke="#10786E"
            fillOpacity={1}
            fill="url(#gradientSales)"
          />
          <Area
            type="monotone"
            dataKey="orders"
            stroke="#F34F6D"
            fillOpacity={1}
            fill="url(#gradientOrders)"
          />
        </AreaChart>
      </div>
    );
  }
}
