import "./App.css";
import "./shared/styles/icons/icons.css";
import "./shared/styles/globals.scss";
import Setting from "./pages/settings/Setting";
import Recommendation from "./pages/recommendation/Recommendation";
import Analytics from "./pages/analytics/Analytics";
import Home from "./pages/home/Home";
import PrivateRoute from "./components/Common/PrivateRoute";
import { Routes, Route, BrowserRouter, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Auth0Provider } from "@auth0/auth0-react";
import StepOne from "./pages/step-one/Step-One";
import Layout from "./components/Layout/Layout";
import StepTwo from "./pages/step-two/Step-Two";
import StepThree from "./pages/step-three/Step-Three";
import StepFour from "./pages/step-four/Step-Four";
import { AuthProvider } from "./utils/user";
import { ActiveItemProvider } from "./contexts/ActiveTabContext";
import { StepOneInitProvider } from "./contexts/StepOneContext";
import { ActiveStoreProvider } from "./contexts/ActiveStore";
import Auth0ProviderWithRedirectCallback from "./components/Auth0/Auth0ProviderWithRedirectCallback";

// const Auth0ProviderWithRedirectCallback = ({ children, ...props }) => {
//   const navigate = useNavigate();
//   const onRedirectCallback = (appState) => {
//     navigate(window.location.pathname);
//   };
//   return (
//     <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
//       {children}
//     </Auth0Provider>
//   );
// };

function App() {
  return (
    <BrowserRouter>
      <ToastContainer />
      <StepOneInitProvider>
        <Auth0ProviderWithRedirectCallback
          domain={process.env.REACT_APP_AUTH0_DOMAIN}
          clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
          authorizationParams={{
            redirect_uri: window.location.origin + "/dashboard",
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          }}
        >
          <AuthProvider>
            <ActiveStoreProvider>
              <ActiveItemProvider>
                <Routes>
                  {/* <Route path="/" exact /> */}
                  <Route path="/step-one" element={<StepOne />} />
                  <Route
                    path="/step-two"
                    element={<PrivateRoute component={StepTwo} />} //{<StepTwo />}
                  />
                  <Route
                    path="/step-three"
                    element={<PrivateRoute component={StepThree} />} //{<StepThree />}
                  />
                  <Route
                    path="/step-four"
                    element={<PrivateRoute component={StepFour} />} //{<StepFour />}
                  />

                  <Route
                    path="/"
                    exact
                    element={<PrivateRoute component={Layout} />}
                  >
                    <Route
                      path="/dashboard"
                      element={<PrivateRoute component={Home} />}
                    />
                    <Route
                      path="/analytics"
                      element={<PrivateRoute component={Analytics} />}
                    />
                    <Route
                      path="/recommendation"
                      element={<PrivateRoute component={Recommendation} />}
                    />
                    <Route
                      path="/settings"
                      element={<PrivateRoute component={Setting} />}
                    />
                  </Route>
                </Routes>
              </ActiveItemProvider>
            </ActiveStoreProvider>
          </AuthProvider>
        </Auth0ProviderWithRedirectCallback>
      </StepOneInitProvider>
    </BrowserRouter>
  );
}

export default App;
