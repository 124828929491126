import React from "react";

const RecommendItem = ({
  color,
  property,
  recIconType,
  title,
  desc,
  // archiveRecommendation,
  // id,
}) => {

  return (
    <div>
      <div className="flex justify-between pl-[10px] rounded-[10px] pr-[30px] pt-[10px] pb-[30px] hover:bg-[#F3F8F3] cursor-pointer">
        <div className="flex ">
          <div
            className={`w-[40px] h-[40px] px-[10px] flex justify-center rounded-[8px]  ${
              color ? `bg-${color}-500` : property
            }`}
          >
            <img
              src={recIconType}
              alt={recIconType}
              className="w-[40px] h-[40px]"
            />
          </div>
          <div className="pl-[15px] pr-[60px]">
            <h6 className="text-[#2A2A2A] text-[16px] font-semibold mb-[2px]">
              {title}
            </h6>
            <p className="text-[#6A6A6A] text-[12px]">{desc}</p>
          </div>
        </div>
        {/* <button
          type="button"
          className="py-[15px]"
          onClick={() => archiveRecommendation(id)}
        >
          <img src={icon} alt="toggle" width={25} />
        </button> */}
      </div>
    </div>
  );
};

export default RecommendItem;
