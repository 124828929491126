import React from "react";
import Icons from "./Icons";

const Note = ({ Icon, title, desc, descOnBold }) => {
  return (
    <div className="bg-[#FEF9EF] pl-[45px] pr-[30px] py-[10px] rounded-[8px] relative">
      <h6 className="text-[#D88913] text-[14px] font-semibold mb-2 flex">
        <span className="absolute left-[14px] text-[18px] font-semibold">
          <Icons iconName={Icon} />
        </span>
        {title}
      </h6>
      <p className="text-[#6A6A6A] text-[12px]">
        {desc} <strong> {descOnBold}</strong>{" "}
      </p>
    </div>
  );
};

export default Note;
