import {apiUrl} from "../utils/constants";
import {GET, PUT} from "./api.service.wrapper";

export const GetAccount = async (user_id) => {
  const response = await GET(`${apiUrl.account}/${user_id}`);
  return response;
};

export const UpdateAccount = async (payload) => {
    const response = await PUT(apiUrl.updateAccount, payload);
    return response;
};