import React, { useState, useEffect } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import { Fragment } from "react";
import SalesCard from "../../components/Common/SalesCard/SalesCard";
import TrafficCard from "../../components/Common/TrafficCard/TrafficCard";
import Icons from "../../components/Common/Icons";
import SalesChart from "../../components/Common/Charts/SalesChart";
import OverViewCard from "../../components/Common/OverViewCard/OverViewCard";
import { GetAnalytics } from "../../services/stats.service";
import { useAuth } from "../../utils/user";
import { useActiveItem } from "../../contexts/ActiveTabContext";
import { useActiveStore } from "../../contexts/ActiveStore";

const Analytics = () => {
  const [analytics, setAnalytics] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [last6Months, setLast6Months] = useState([]);
  const { activeStore } = useActiveStore();
  const [returningCustomers, setReturningCustomers] = useState(0);
  const [last6MonthNames, setLast6MonthNames] = useState([]);
  const { authUser, labels, icons } = useAuth();
  const [isLoadingAuthUser, setIsLoadingAuthUser] = useState(true);
  const [isAuthUserChecked, setIsAuthUserChecked] = useState(false);
  const { handleItemClick } = useActiveItem();
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const yearNames = [
    "2024",
    "2023",
    "2022",
    "2021",
    "2020",
  ];

  function getPreviousMonthDates() {
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() - 1);
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1; // getMonth() returns 0-11
    const lastDayOfMonth = new Date(year, month, 0).getDate();

    return {
      start_date: `${year}-${month.toString().padStart(2, "0")}-01`,
      end_date: `${year}-${month
        .toString()
        .padStart(2, "0")}-${lastDayOfMonth}`,
    };
  }

  const getCurrentDate = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1; // getMonth() returns 0-11
    const lastDayOfMonth = new Date(year, month, 0).getDate();

    return {
      start_date: `${year}-${month.toString().padStart(2, "0")}-01`,
      end_date: `${year}-${month
        .toString()
        .padStart(2, "0")}-${lastDayOfMonth}`,
    };
  };

  const [paramsForCurrentMonth, setParamsForCurrentMonth] = useState(
    getCurrentDate()
  );
  const [paramsForLastMonth, setParamsForLastMonth] = useState(
    getPreviousMonthDates()
  );

  const changeYear = (value) => {
    let currentMonth = monthNames.indexOf(selected.name) + 1;
    setParamsForCurrentMonth({
      start_date: `${value}-${currentMonth
        .toString()
        .padStart(2, "0")}-01`,
        end_date: `${value}-${currentMonth
          .toString()
          .padStart(2, "0")}-31`,
        });
        
    let lastMonth = monthNames.indexOf(selected.name) === 0 ? 12 : monthNames.indexOf(selected.name);
    let lastYear = monthNames.indexOf(selected.name) === 0 ? yearNames[yearNames.indexOf(value) + 1 ] : value;
    setParamsForLastMonth({
      start_date: `${lastYear}-${lastMonth
        .toString()
        .padStart(2, "0")}-01`,
      end_date: `${lastYear}-${lastMonth
        .toString()
        .padStart(2, "0")}-31`,
    });

    setSelectedYear({ name: value });
  };

  const changeMonth = (value) => {
    let currentMonth = monthNames.indexOf(value) + 1;
    
    
    setParamsForCurrentMonth({
      start_date: `${selectedYear.name}-${currentMonth
        .toString()
        .padStart(2, "0")}-01`,
      end_date: `${selectedYear.name}-${currentMonth
        .toString()
        .padStart(2, "0")}-31`,
      });
      
    let lastMonth = monthNames.indexOf(value) === 0 ? 12 : monthNames.indexOf(value);
    let lastYear = monthNames.indexOf(value) === 0 ? yearNames[yearNames.indexOf(selectedYear.name) + 1 ] : selectedYear.name;

    setParamsForLastMonth({
      start_date: `${lastYear}-${lastMonth
        .toString()
        .padStart(2, "0")}-01`,
      end_date: `${lastYear}-${lastMonth
        .toString()
        .padStart(2, "0")}-31`,
    });

    setSelected({ name: value });
  };

  useEffect(() => {
    handleItemClick("item1");
    // Define a timeout duration (e.g., 5000 milliseconds)
    const timeoutDuration = 2000;

    // Set up a timeout to wait for authUser
    const timeout = setTimeout(() => {
      setIsAuthUserChecked(true); // Indicates the check is complete
    }, timeoutDuration);

    return () => clearTimeout(timeout); // Clear the timeout if the component unmounts
  }, []); // Empty dependency array to run only once after mount

  useEffect(() => {
    if (isAuthUserChecked) {
      salesDataa();
    }
  }, [authUser, activeStore, isAuthUserChecked]);

  useEffect(() => {
    if (isAuthUserChecked) {
      setIsLoadingAuthUser(false);
      if (!authUser || !authUser.stores || authUser.stores.length < 1) {
        navigate("/settings");
      }

      const fetchDataWithStoreUrl = async () => {
        if (authUser && authUser?.stores) {
          fetchDataa(paramsForCurrentMonth, paramsForLastMonth);
        }
      };
      fetchDataWithStoreUrl();
    }
  }, [authUser, paramsForCurrentMonth, isAuthUserChecked, activeStore]);

  const calculatePercentageChange = (current, last) => {
    if (last === 0) {
      return current > 0 ? "+100%" : "0%";
    }
    const change = ((current - last) / last) * 100;
    return (change >= 0 ? "+" : "") + change.toFixed(2) + "%";
  };

  const salesDataa = async () => {
    const store_url = activeStore?.url;
    if (store_url) {
      setLoading(true);

      const monthName = [];
      let today = new Date();

      const fromMonth = new Date(today);

      fromMonth.setMonth(today.getMonth() - 5);
      const months = {
        start_date: `${fromMonth.getFullYear()}-${(fromMonth.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-01`,
        end_date: `${today.getFullYear()}-${(today.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${new Date(
          today.getFullYear(),
          today.getMonth() + 1,
          0
        ).getDate()}`,
      };

      for (let i = 5; i >= 0; i--) {
        let monthIndex = (today.getMonth() - i + 12) % 12;
        monthName.push(monthNames[monthIndex]);
      }

      setLast6MonthNames(monthName);

      const details = await Promise.all([
        GetAnalytics({
          ...months,
          store_url,
        }),
      ]);

      const orders = details[0]?.data.data.orders;
      const grouped = orders.reduce((acc, order) => {
        const date = new Date(order.created_at);
        const monthYearKey = `${( date.getMonth() + 12 ) % 12}`;

        if (!acc[monthNames[monthYearKey]]) {
          acc[monthNames[monthYearKey]] = { orders: [], total_price: 0 };
        }
        acc[monthNames[monthYearKey]].orders.push(order);
        acc[monthNames[monthYearKey]].total_price += parseFloat(
          order.total_price
        );
        return acc;
      }, {});

      setLast6Months(grouped);

      setLoading(false);
    }
  };

  const fetchDataa = async (paramsForCurrentMonth, paramsForLastMonth) => {
    const store_url = activeStore?.url;
    if (store_url) {
      setLoading(true);
      const [currentMonthResponse, lastMonthResponse] = await Promise.all([
        GetAnalytics({
          ...paramsForCurrentMonth,
          store_url,
        }),
        GetAnalytics({
          ...paramsForLastMonth,
          store_url,
        }),
      ]);

      const crrentMonthData = currentMonthResponse?.data?.data;
      const emailCounts = {};
      crrentMonthData.orders.forEach((order) => {
        const email = order?.customer?.email || "";
        if (emailCounts[email]) {
          emailCounts[email]++;
        } else {
          emailCounts[email] = 1;
        }
      });

      // Filter duplicates (count > 1)
      const duplicateEmails = Object.keys(emailCounts).filter(
        (email) => emailCounts[email] > 1
      );

      setReturningCustomers(duplicateEmails.length);
      const lastMonthData = lastMonthResponse?.data?.data;

      const currentTotalOrderAmount = crrentMonthData?.totalOrderAmount;
      const lastTotalOrderAmount = lastMonthData?.totalOrderAmount;
      const percentageChangeOrderAmount = calculatePercentageChange(
        currentTotalOrderAmount,
        lastTotalOrderAmount
      );

      const currentTotalOrderCount = crrentMonthData?.totalOrderCount;
      const lastTotalOrderCount = lastMonthData?.totalOrderCount;
      const percentageChangeOrderCount = calculatePercentageChange(
        currentTotalOrderCount,
        lastTotalOrderCount
      );

      const currentCustomerCount = crrentMonthData?.customerCount;
      const lastCustomerCount = lastMonthData?.customerCount;
      const percentageChangeCustomerCount = calculatePercentageChange(
        currentCustomerCount,
        lastCustomerCount
      );

      const currentAvgOrderValue = crrentMonthData?.avgOrderValue;
      const lastAvgOrderValue = lastMonthData?.avgOrderValue;
      const percentageChangeAvgOrderValue = calculatePercentageChange(
        currentAvgOrderValue,
        lastAvgOrderValue
      );

      setAnalytics({
        totalOrderAmount: currentTotalOrderAmount,
        percentageChangeOrderAmount,
        totalOrderCount: currentTotalOrderCount,
        percentageChangeOrderCount,
        customerCount: currentCustomerCount,
        percentageChangeCustomerCount,
        avgOrderValue: currentAvgOrderValue,
        percentageChangeAvgOrderValue,
      });

      setLoading(false);
    }
  };

  const [selected, setSelected] = useState({
    name: monthNames[new Date().getMonth()],
  });
  const [selectedYear, setSelectedYear] = useState({
    name: yearNames[0],
  });

  const resetFilter = () =>{
      setParamsForCurrentMonth(getCurrentDate);
      setParamsForLastMonth(getPreviousMonthDates);

      setSelected({ name: monthNames[0]});
      setSelectedYear({name: yearNames[0]});
  }

  useEffect(() => {
    handleItemClick("item3");
  }, []);

  if (isLoadingAuthUser) {
    return <div>Loading...</div>; // Or any other loading indicator
  }

  return (
    <div>
      <div className="md:flex justify-between items-center">
        <h4 className="text-[20px] text-[#2A2A2A] font-medium">
          {labels["analytics_general_overview"]}
        </h4>
        <div className="flex items-center">
          <div className="flex items-center border border-[#9197B3] w-[360px]  rounded-[4px] mx-[12.5px]">
            <span className="justify-start px-[10px] text-[#9197B3] text-[16px] items-center">
              Month:
            </span>
            <Listbox value={selected} onChange={changeMonth}>
              <div className="relative  flex-1">
                <Listbox.Button className="text-[#9197B3] py-[9px] text-[16px] font-medium relative w-full cursor-pointer rounded-lg bg-white  text-left">
                  <span className="block truncate text-[#157810]">
                    {selected.name}
                  </span>
                  <span className="bg-[#157810] text-[#fff] pointer-events-none rounded-r-[3px] px-[3px] absolute inset-y-0 right-0 flex items-center ">
                    <Icons iconName="icon-arrow-up" />
                  </span>
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {monthNames.map((value, index) => (
                      <Listbox.Option
                        key={index}
                        className={({ active }) =>
                          `relative cursor-pointer select-none py-2 px-5 ${
                            active
                              ? "bg-amber-100 text-amber-900"
                              : "text-gray-900"
                          }`
                        }
                        value={value}
                      >
                        {({ selected }) => (
                          <>
                            <span
                              className={`block ${
                                selected ? "font-medium" : "font-normal"
                              }`}
                            >
                              {value}
                            </span>
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
          </div>
          <div className="flex items-center border border-[#9197B3] w-[360px]  rounded-[4px] mx-[12.5px]">
            <span className="justify-start px-[10px] text-[#9197B3] text-[16px] items-center">
              Year:
            </span>
            <Listbox value={selectedYear} onChange={changeYear}>
              <div className="relative  flex-1">
                <Listbox.Button className="text-[#9197B3] py-[9px] text-[16px] font-medium relative w-full cursor-pointer rounded-lg bg-white  text-left">
                  <span className="block truncate text-[#157810]">
                    {selectedYear.name}
                  </span>
                  <span className="bg-[#157810] text-[#fff] pointer-events-none rounded-r-[3px] px-[3px] absolute inset-y-0 right-0 flex items-center ">
                    <Icons iconName="icon-arrow-up" />
                  </span>
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {yearNames.map((value, index) => (
                      <Listbox.Option
                        key={index}
                        className={({ active }) =>
                          `relative cursor-pointer select-none py-2 px-5 ${
                            active
                              ? "bg-amber-100 text-amber-900"
                              : "text-gray-900"
                          }`
                        }
                        value={value}
                      >
                        {({ selected }) => (
                          <>
                            <span
                              className={`block ${
                                selected ? "font-medium" : "font-normal"
                              }`}
                            >
                              {value}
                            </span>
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
          </div>
          <button
            type="button"
            className="btn flex py-[9px] px-[10px] items-center border-[#157810] border text-[14px] font-semibold rounded-[4px] text-[#157810]"
            onClick={resetFilter}
          >
            <span className="text-[16px] px-[5px] font-semibold">
              <Icons iconName="icon-filter" />
            </span>
            {labels["analytics_reset_filter"]}
          </button>
        </div>
      </div>
      <div className="flex  my-[30px] flex-wrap">
        <div className="lg:max-w-[400px] w-full">
          <SalesCard
            cardIcon={icons['sales-icon.svg']}
            title={labels["analytics_sales"]}
            desc="Net Sales"
            totalSale={`$${analytics.totalOrderAmount ?? "0"}`}
            totalInc={`${analytics.percentageChangeOrderAmount ?? "0"}`}
            orderTotal="Orders"
            totalOrders={analytics.totalOrderCount ?? "0"}
            totalOrd={`${analytics.percentageChangeOrderCount ?? "0"}`}
          />
        </div>
        <div className="lg:max-w-[400px] w-full">
          <SalesCard
            cardIcon={icons['users.svg']}
            title={labels["analytics_customers"]}
            desc="Total"
            totalSale={analytics.customerCount ?? "0"}
            totalInc={`${
              analytics.percentageChangeCustomerCount >= 0 ? "+" : ""
            }${analytics.percentageChangeCustomerCount ?? "0"}`}
            orderTotal="Returning"
            totalOrders={`${returningCustomers ?? "0"}`}
            totalOrd={`${analytics.percentageChangeCustomerCount ?? "0"}`}
          />
        </div>
        <div className="flex-1 ">
          <TrafficCard
            cardIcon={icons['traffic.svg']}
            title={labels["analytics_traffic"]}
            desc="Visitors"
            totalSale={`${analytics?.visitors ? analytics?.visitors : "N/A"}`}
            totalInc="+0%"
            orderTotal="Returning"
            sessions="Sessions"
            isActive={false}
          />
        </div>
      </div>
      <div className="">
        <h4 class="text-[20px] text-[#2A2A2A] font-medium mb-[30px]">
          {labels["analytics_general_overview"]}
        </h4>
        <div className="flex  my-[30px] flex-wrap">
          <div className="lg:max-w-[250px] w-full">
            <OverViewCard
              cardIcon={icons['rate.svg']}
              title={labels["analytics_conversion_rate"]}
              totalSale={`${
                analytics?.conversionRate ? analytics?.conversionRate : "N/A"
              }`}
              totalInc="+0%"
              generalOver="active"
              isActive={false}
            />
          </div>
          <div className="lg:max-w-[250px] w-full">
            <OverViewCard
              cardIcon={icons['average-order.svg']}
              title={labels["analytics_avg_order_value"]}
              totalSale={`$${
                analytics?.avgOrderValue ? analytics?.avgOrderValue : "0"
              }`}
              totalInc={`$${
                analytics?.percentageChangeAvgOrderValue
                  ? analytics?.percentageChangeAvgOrderValue
                  : "N/A"
              }`}
              generalOver="active"
              isActive={true}
            />
          </div>
          <div className="lg:max-w-[250px] w-full">
            <OverViewCard
              cardIcon={icons['cart.svg']}
              title={labels["analytics_abondoned_cart_rate"]}
              totalSale={`${
                analytics?.abandonedCartRate
                  ? analytics?.abandonedCartRate
                  : "N/A"
              }`}
              totalInc="+0%"
              generalOver="active"
              isActive={false}
            />
          </div>
        </div>
      </div>

      <div className="">
        <h4 class="text-[20px] text-[#2A2A2A] font-medium mb-[30px]">
          {labels["analytics_deeper_dive"]}
        </h4>

        <div className="bg-[#fff] sales-report shadow-[10px_0_60px_0_rgba(226,236,249,0.5)] px-[15px] py-[20px]  rounded-[8px]">
          <div className="flex my-[15px]">
            <h4 class="text-[20px] text-[#2A2A2A] font-medium mb-[30px]">
              {labels["analytics_sales_report"]}
            </h4>
            <div className="flex mx-[25px]">
              <div className="flex mx-[15px] rounded-[4px] items-center bg-[#F9F9F9] md:w-[153px] h-[40px] justify-center">
                <div className="bg-[#008000] w-[16px] h-[12px] rounded-[5px]"></div>
                <span className="text-[#2A2A2A] px-[15px]">Sales</span>
              </div>
              <div className="flex mx-[15px] rounded-[4px] items-center bg-[#F9F9F9] md:w-[153px] h-[40px] justify-center">
                <div className="bg-[#EE6C4D] w-[16px] h-[12px] rounded-[5px]"></div>
                <span className="text-[#2A2A2A] px-[15px]">Total Orders</span>
              </div>
            </div>
          </div>
          {last6Months && (
            <SalesChart sales={last6Months} last6MonthNames={last6MonthNames} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Analytics;
