import React from 'react'
import { useAuth } from '../../utils/user'

const Logo = () => {
  const {icons} = useAuth()
  return (
    <div>
        <img src={icons['logo.svg']} alt='logo'/>
    </div>
  )
}

export default Logo