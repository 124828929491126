import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
// import Notification from "../../Common/Notification";
import { useAuth } from "../../../utils/user";
import { useActiveStore } from "../../../contexts/ActiveStore";
import StoreDropdown from "./StoreDropdown";
import UserProfile from "./UserProfile";

const Header = () => {
  const { authUser, labels, icons } = useAuth();
  const [selectedStore, setSelectedStore] = useState("");
  const [stores, setStores] = useState([]);

  const { handleStoreClick } = useActiveStore();
  const location = useLocation();

  // Improved heading determination
  const headings = {
    "/": labels["menu_dashboard"],
    "/recommendation": labels["menu_recommendations"],
    "/analytics": labels["menu_analytics"],
    "/settings": labels["menu_settings"],
    "/support": labels["menu_support"],
    "/step-one": "",
    "/step-two": "",
    "/step-three": "",
  };
  const heading = headings[location.pathname] || labels["menu_dashboard"];

  const handleStoreSelect = (storeObj) => {
    setSelectedStore(storeObj);
    const matchingStore = authUser.stores.find(
      (store) => store.store_name === storeObj.name
    );
    handleStoreClick(matchingStore);
    localStorage.setItem("active_shop", matchingStore.url);
  };

  useEffect(() => {
    if (authUser && authUser.stores) {
      const storeURLs = authUser.stores
        .filter((store) => store.api_token !== null)
        .map((store) => ({
          name: store.store_name,
        }));

      setStores(storeURLs);
      const activeShop = localStorage.getItem("active_shop");
      const defaultStore = authUser.stores[0] || {
        store_name: "Connect your first store",
        url: "",
      };

      const matchingStore =
        authUser.stores.find(
          (store) => store.url === activeShop && store.api_token !== null
        ) || defaultStore;
      handleStoreClick(matchingStore);
      setSelectedStore({ name: matchingStore.store_name });
    }
  }, [authUser]); // The effect runs whenever authUser changes
  return (
    <>
      <div className="flex justify-between items-center py-[33px]">
        <h2 className="md:text-[30px] sm:text-[24px] text-[18px] text-[2A2A2A] font-semibold capitalize">
          {heading}
        </h2>
        <div className="flex items-center">
          <StoreDropdown
            selectedStore={selectedStore}
            handleStoreSelect={handleStoreSelect}
            stores={stores}
            icons={icons}
            labels={labels}
          />
          {/* <Notification /> */}
          <UserProfile
            userPicture={authUser?.auth0_picture || icons["customer.png"]}
            userName={authUser?.first_name}
            labels={labels}
          />
        </div>
      </div>
    </>
  );
};

export default Header;
