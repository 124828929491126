import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import Note from "../../components/Common/Note";
import Score from "../../components/Common/Score/Score";
import RecommendItem from "../../components/Common/Recommend/RecommendItem";
import AnalyticsCard from "../../components/Common/AnalyticsCard/AnalyticsCard";
import Button from "../../components/Common/Button";
import GuageChart from "../../components/Common/Charts/GuageChart";
import RecommendChart from "../../components/Common/Charts/RecommendChart";
import SalesChart from "../../components/Common/Charts/SalesChart";
import { GetAnalytics } from "../../services/stats.service";
import { GetDashboardOptimization } from "../../services/optimizations.service";
import { useAuth } from "../../utils/user";
import { useActiveStore } from "../../contexts/ActiveStore";
import {
  GetRecommendations,
  UpdateRecommendationStatus,
} from "../../services/recommendation.service";
import { useActiveItem } from "../../contexts/ActiveTabContext";

const Home = () => {
  const navigate = useNavigate();
  const { authUser, labels, icons } = useAuth();
  const { activeStore } = useActiveStore();
  const { handleItemClick } = useActiveItem();

  const [stats, setStats] = useState({});
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  function getPreviousMonthDates() {
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() - 1);
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1; // getMonth() returns 0-11
    const lastDayOfMonth = new Date(year, month, 0).getDate();
  
    return {
      start_date: `${year}-${month.toString().padStart(2, '0')}-01`,
      end_date: `${year}-${month.toString().padStart(2, '0')}-${lastDayOfMonth}`,
    };
  }

  const getCurrentDate = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1; // getMonth() returns 0-11
    const lastDayOfMonth = new Date(year, month, 0).getDate();
  
    return {
      start_date: `${year}-${month.toString().padStart(2, '0')}-01`,
      end_date: `${year}-${month.toString().padStart(2, '0')}-${lastDayOfMonth}`,
    };
  };

  const [paramsForCurrentMonth, setParamsForCurrentMonth] = useState(getCurrentDate());
  const [paramsForLastMonth, setParamsForLastMonth] = useState(getPreviousMonthDates());
  const [recommendations, setRecommendations] = useState([]);
  const [recommendationsCount, setRecommendationsCount] = useState(0);
  const [issueCount, setIssueCount] = useState(0);
  const [issueData, setIssueData] = useState([]);
  const [warningCount, setWarningCount] = useState(0);
  const [warningData, setWarningData] = useState([]);
  const [opportunityCount, setOpportunityCount] = useState(0);
  const [opportunityData, setOpportunityData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [last6Months, setLast6Months] = useState([]);
  const [dashboardOptimizations, setDashboardOptimizations] = useState([]);
  const [last6MonthNames, setLast6MonthNames] = useState([]);
  const [isLoadingAuthUser, setIsLoadingAuthUser] = useState(true);
  const [isAuthUserChecked, setIsAuthUserChecked] = useState(false);

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const fetchRecommendationData = async () => {
    setLoading(true);
    if (activeStore) {
      const recommendationsResponse = await GetRecommendations(activeStore.id);
      const data = recommendationsResponse?.data?.data;

      const statusOrder = {
        Warning: 1,
        Issue: 2,
        Opportunity: 3,
      };

      
      // Filter, sort, and slice the data
      const sortedData = data
        ?.filter((item) => item.status === "Open") // Filter for 'open' status
        .sort((a, b) => {
          return (statusOrder[a.type] || 0) - (statusOrder[b.type] || 0);
        })
        .slice(0, 3); // Slicing the top 3 items

      setRecommendations(sortedData);

      // Set filtered data for each type and extract only the 'recommendation_type' key
      const issueData =
        data
          ?.filter((item) => capitalizeFirstLetter(item.type) === "Issue" && item.status === "Open")
          ?.map((item) => item.recommendation_type) || [];
      setIssueData(issueData);

      const warningData =
        data
          ?.filter((item) => capitalizeFirstLetter(item.type) === "Warning" && item.status === "Open")
          ?.map((item) => item.recommendation_type) || [];
      setWarningData(warningData);

      const opportunityData =
        data
          ?.filter((item) => capitalizeFirstLetter(item.type) === "Opportunity" && item.status === "Open")
          ?.map((item) => item.recommendation_type) || [];
      setOpportunityData(opportunityData);
      // Set recommendation count
      setRecommendationsCount(data?.filter((item) => item.status === "Open").length || 0);

      // Set issue count
      const issueCount =
        data?.filter((item) => capitalizeFirstLetter(item.type) === "Issue" && item.status === "Open").length || 0;
      setIssueCount(issueCount);

      // Set warning count
      const warningCount =
        data?.filter((item) => capitalizeFirstLetter(item.type) === "Warning" && item.status === "Open").length || 0;
      setWarningCount(warningCount);

      // Set opportunity count
      const opportunityCount =
        data?.filter((item) => capitalizeFirstLetter(item.type) === "Opportunity" && item.status === "Open" ).length || 0;
      setOpportunityCount(opportunityCount);

      setLoading(false);
    }
  };

  const fetchDataa = async () => {
    const store_url = activeStore?.url;
    if (store_url) {
      setLoading(true);
      const [currentMonthResponse, lastMonthResponse] = await Promise.all([
        GetAnalytics({
          ...paramsForCurrentMonth,
          store_url,
        }),
        GetAnalytics({
          ...paramsForLastMonth,
          store_url,
        }),
      ]);
      
      const crrentMonthData = currentMonthResponse?.data?.data;
      const lastMonthData = lastMonthResponse?.data?.data;

      const currentTotalAmount = crrentMonthData?.totalOrderAmount;
      const lastTotalAmount = lastMonthData?.totalOrderAmount;
      const percentageChangeAmount = calculatePercentageChange(
        currentTotalAmount,
        lastTotalAmount
      );

      const currentTotalOrderCount = crrentMonthData?.totalOrderCount;
      const lastTotalOrderCount = lastMonthData?.totalOrderCount;
      const percentageChangeOrderCount = calculatePercentageChange(
        currentTotalOrderCount,
        lastTotalOrderCount
      );

      setStats({
        totalOrderAmount: currentTotalAmount,
        totalOrderCount: currentTotalOrderCount,
        percentageChangeAmount,
        percentageChangeOrderCount,
      });

      setLoading(false);
    }
  };

  const getOptimization = async () => {
    if (activeStore) {
      const [dashboardOptimization] = await Promise.all([
        GetDashboardOptimization(activeStore.id),
      ]);

      setDashboardOptimizations(dashboardOptimization?.data?.data[0]);
      
    }
  };

  const salesDataa = async () => {
    const store_url = activeStore?.url;
    if (store_url) {
      setLoading(true);

      const monthName = [];
      let today = new Date();

      const fromMonth = new Date(today);

      fromMonth.setMonth(today.getMonth() - 4);
      const months = {
        start_date: `${fromMonth.getFullYear()}-${(fromMonth.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-01`,
        end_date: `${today.getFullYear()}-${(today.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${new Date(
          today.getFullYear(),
          today.getMonth() + 1,
          0
        ).getDate()}`,
      };

      for (let i = 4; i >= 0; i--) {
        monthName.push(monthNames[(today.getMonth() - i + 12) % 12]);
      }
      
      setLast6MonthNames(monthName);
      
      const details = await Promise.all([
        GetAnalytics({
          ...months,
          store_url,
        }),
      ]);
      const orders = details[0]?.data.data.orders;
      const grouped = orders.reduce((acc, order) => {
        const date = new Date(order.created_at);
        const monthYearKey = `${date.getMonth()}`;

        if (!acc[monthNames[monthYearKey]]) {
          acc[monthNames[monthYearKey]] = { orders: [], total_price: 0 };
        }
        acc[monthNames[monthYearKey]].orders.push(order);
        acc[monthNames[monthYearKey]].total_price += parseFloat(
          order.total_price
        );
        return acc;
      }, {});
      
      setLast6Months(grouped);

      setLoading(false);
    }
  };

  const calculatePercentageChange = (current, last) => {
    if (last === 0) {
      return current > 0 ? "+100%" : "0%";
    }
    const change = ((current - last) / last) * 100;
    return (change >= 0 ? "+" : "") + change.toFixed(2) + "%";
  };

  const archiveRecommendation = async (id) => {
    const response = await UpdateRecommendationStatus(
      id,
      "Archived",
      new Date()
    );

    if (response.data.data) {
      fetchRecommendationData();
    }
  };

  useEffect(() => {
    handleItemClick("item1");
    // Define a timeout duration (e.g., 5000 milliseconds)
    const timeoutDuration = 2000;

    // Set up a timeout to wait for authUser
    const timeout = setTimeout(() => {
      setIsAuthUserChecked(true); // Indicates the check is complete
    }, timeoutDuration);

    return () => clearTimeout(timeout); // Clear the timeout if the component unmounts
  }, []); // Empty dependency array to run only once after mount

  useEffect(() => {
    if (isAuthUserChecked) {
      setIsLoadingAuthUser(false);
      if (!authUser || !authUser.stores || authUser.stores.length < 1) {
        navigate("/settings");
      }
      fetchRecommendationData();
      getOptimization();
      fetchDataa();
      salesDataa();
    }
  }, [isAuthUserChecked, authUser, navigate, activeStore]);

  if (isLoadingAuthUser) {
    return <div>Loading...</div>; // Or any other loading indicator
  }

  if (!authUser) {
    return;
  }


  return (
    <div>
      <div className="lg:grid lg:grid-cols-2  gap-4">
        <div className="bg-white shadow-[10px_0_60px_0_rgba(226,236,249,0.5)] rounded-[10px] p-[18px]">
          <div className="flex lg:justify-start justify-center mb-[20px]">
            <img src={icons['opt-svg.svg']} alt="optimization" />
            <h6 className="text-[16px] text-[#2A2A2A] capitalize font-semibold px-[10px]">
              {labels["dashboard_your_optimization_score"]}
            </h6>
          </div>
          {/* chart */}
          <div className="relative">
              <GuageChart optimizationScore={dashboardOptimizations?.score || 0 } />
          </div>
          {/* chart  */}
          <div className="mt-[15px]">
            {dashboardOptimizations && dashboardOptimizations.gear_name && (
              <Note
                title={dashboardOptimizations.gear_name}
                desc={dashboardOptimizations.gear_description}
              />
            )}
          </div>
        </div>
        <div className="bg-white xl:h-[450px] mb-[65px] shadow-[10px_0_60px_0_rgba(226,236,249,0.5)] rounded-[10px] p-[18px]">
          <div className="xl:flex justify-between items-center">
            {/* chart */}
            <div className="">
              <RecommendChart
                recommendationsCount={recommendationsCount}
                issueCount={issueCount}
                issueData={issueData}
                warningCount={warningCount}
                warningData={warningData}
                opportunityCount={opportunityCount}
                opportunityData={opportunityData}
              />
              <div className="py-[30px] flex  justify-center">
                <Button
                  btntitle={labels["dashboard_improve_your_score_now"]}
                  btnLink="/recommendation"
                />
              </div>
            </div>

            {/* chart */}
            <div className="xl:block sm:flex justify-between">
              <Score
                icon={icons['alert.svg']}
                color={issueData[0]?.icon_color}
                title={issueData[0]?.type ?? "Issues"}
                total={issueCount ?? 0}
                property={'alert'}
              />
              <Score
                icon={icons['warning.svg']}
                color={warningData[0]?.icon_color}
                title={warningData[0]?.type ?? "Warnings"}
                total={warningCount ?? 0}
                property={'warning'}
              />

              <Score
                icon={icons['bulb.svg']}
                color={opportunityData[0]?.icon_color}
                title={opportunityData[0]?.type ?? "Opportunities"}
                total={opportunityCount ?? 0}
                property={'insight'}
              />
            </div>
          </div>
        </div>
        <div className="bg-white shadow-[10px_0_60px_0_rgba(226,236,249,0.5)] rounded-[10px] p-[18px]">
          <div className="flex justify-start">
            <img src={icons['recommend-new.png']} alt="recom" />
            <h6 className="text-[16px] text-[#2A2A2A] capitalize font-semibold px-[10px]">
              {labels['menu_recommendations']}
            </h6>
          </div>
          <div className="py-[30px]">
            {recommendations &&
              recommendations.map((rec, index) => (
                
                <RecommendItem
                  color={rec.recommendation_type?.icon_color}
                  recIconType={
                    capitalizeFirstLetter(rec.type) === "Issue"
                      ? icons['alert.svg']
                      : capitalizeFirstLetter(rec.type) === "Warning"
                      ? icons['warning.svg']
                      : icons['bulb.svg']
                  }
                  title={rec.description ?? ""}
                  property={capitalizeFirstLetter(rec.type) === "Issue"
                  ? 'alert'
                  : capitalizeFirstLetter(rec.type) === "Warning"
                  ? 'warning'
                  : 'insight'}
                  desc={rec.recommended_action}
                  id={rec.id}
                  archiveRecommendation={archiveRecommendation}
                />
              ))}
          </div>
          <div className="py-[30px] flex  justify-end">
            {recommendations && recommendations.length > 0 && (
              <Button
                btntitle="View all recommendations"
                btnLink="/recommendation"
              />
            )}

            {recommendations && recommendations.length < 1 && (
              <span
                style={{ float: "left" }}
                className="text-[12px] text-[#900]"
              >
                We are preparing recommendations for you
              </span>
            )}
          </div>
        </div>
        <div className="bg-white lg:mt-[-65px] shadow-[10px_0_60px_0_rgba(226,236,249,0.5)] rounded-[10px] p-[18px]">
          <div className="flex justify-start mb-[25px]">
            <img src={icons['analyticss.svg']} alt="analytics" />
            <h6 className="text-[16px] text-[#2A2A2A] capitalize font-semibold px-[10px]">
              {labels['menu_analytics']}
            </h6>
          </div>
          <div className="md:flex justify-between mb-[15px]">
            <AnalyticsCard
              cardIcon={icons['sales.svg']}
              title="Sales"
              desc="Sales this month"
              totalSale={`$${stats?.totalOrderAmount?.toFixed(2) ?? 0}`}
              //"$1687.87"
              totalInc={`${stats?.percentageChangeAmount || 0}`} //"^50%"
            />
            <AnalyticsCard
              cardIcon={icons['orders.svg']}
              title="Orders"
              desc="Total Orders this month"
              totalSale={stats?.totalOrderCount || 0}
              // "154"
              totalInc={`${stats?.percentageChangeOrderCount || 0}`}
            />
          </div>
          <div className=" py-[15px]">
            <h2 className="text-[#2A2A2A] text-[18px] font-semibold">
              {labels["dashboard_sales_at_a_glance"]}
            </h2>
          </div>
          <div className="mt-[15px]">
            {last6Months && (
              <SalesChart
                sales={last6Months}
                last6MonthNames={last6MonthNames}
                isHome={true}
              />
            )}
          </div>
          <div className="my-[15px]">
            <Link
              to="/analytics"
              className="text-[#157810] text-[14px] font-semibold"
            >
              {labels["dashboard_view_analytics"]}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
