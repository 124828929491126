import { apiUrl } from "../utils/constants";
import { GET, PUT } from "./api.service.wrapper";

export const GetTypes = async () => {
  const response = await GET(apiUrl.recommendationTypes);
  return response;
};

export const GetRecommendations = async (store_id) => {
  const response = await GET(apiUrl.getRecommendations + `/${store_id}`);
  return response;
};

export const UpdateRecommendationStatus = async (
  rec_id,
  status,
  timestamp = new Date()
) => {
  const response = await PUT(apiUrl.putRecommendation, {
    rec_id,
    status,
    timestamp,
  });
  return response;
};
