import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { UpdateUser } from "../../services/auth.service";
import { useAuth } from "../../utils/user";
import FieldLabel from "../Common/FieldLabel";

const BasicInfoForm = () => {
  const { authUser, setAuthUser, labels } = useAuth();
  const [birthDate, setBirthDate] = useState(new Date());
  const [initialValues, setInitialValues] = useState({});
  const [uploadSuccess, setUploadSuccess] = useState(null);

  const validationSchema = Yup.object({
    firstName: Yup.string().required("Please enter your first name"),
    lastName: Yup.string().required("Please enter your last name"),
    birthDate: Yup.date().required("Please enter your birth date"),
    email: Yup.string().email().required("Please enter your email"),
    title: Yup.string().required("Please enter your title"),
    phoneNumber: Yup.string().required("Please enter your phone number"),
  });

  useEffect(() => {
    let timer;

    if (uploadSuccess) {
      timer = setTimeout(() => {
        setUploadSuccess(null);
      }, 4000); // 4000 milliseconds = 4 seconds
    }

    // Clear the timer if the component unmounts or if uploadSuccess changes again
    return () => clearTimeout(timer);
  }, [uploadSuccess]); // This effect runs every time imageSuccess changes

  useEffect(() => {
    // console.log(authUser?.birth_date);
    setBirthDate(
      authUser?.birth_date ? new Date(authUser?.birth_date) : new Date()
    );
    // Update initial values when authUser changes
    setInitialValues({
      firstName: authUser?.first_name || "",
      lastName: authUser?.last_name || "",
      birthDate: authUser?.birth_date ? authUser?.birth_date : new Date(), // Or a specific date from authUser
      email: authUser?.email || "",
      title: authUser?.title || "",
      phoneNumber: authUser?.phone || "",
      userId: authUser?.id,
    });
  }, [authUser]);

  const onSubmit = async (values) => {
    const paylaod = {
      title: values.title,
      first_name: values.firstName,
      last_name: values.lastName,
      phone: values.phoneNumber,
      email: values.email,
      user_id: initialValues.userId,
      birth_date: birthDate,
    };

    if (await UpdateUser(paylaod)) {
      setAuthUser((prevValues) => ({
        ...prevValues,
        first_name: values.firstName || "",
        last_name: values.lastName || "",
        email: values.email || "",
        title: values.title || "",
        birth_date: birthDate || "",
        phone: values.phoneNumber || "",
      }));

      setUploadSuccess("Updated Successfully");
    }
  };

  const handleSubmit = async (values, { resetForm }) => {
    await onSubmit(values);
    resetForm();
  };

  const renderError = (message) => (
    <p className="text-[#ff0000] text-[12px]">{message}</p>
  );

  const renderSuccessMsg = (message) => (
    <div
      className="flex items-center justify-start bg-green-100 border-l-4 border-green-500 text-green-700 p-4"
      role="alert"
    >
      <svg
        className="fill-current w-4 h-4 mr-2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <path d="M10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10S15.523 0 10 0zM8 15l-5-5 1.41-1.41L8 12.17l7.59-7.59L17 6l-9 9z" />
      </svg>
      <p className="font-bold">{message}</p>
    </div>
  );

  return (
    <div
      id="basicinfo"
      className="bg-[#fff] mb-[24px] shadow-[0px_20px_27px_0_rgba(0,0,0,5%)] rounded-[16px] px-[24px] py-[16px]"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        <Form>
          <h2 className="text-[#2A2A2A] text-[20px] font-semibold pb-[32px] pt-[24px]">
            {labels?.settings_basic_info}
          </h2>
          <div className="grid grid-cols-2 gap-6">
            <div className="inputFormGroup">
              <FieldLabel label="First Name" htmlFor="firstName" />
              <div className="control">
                <Field
                  name="firstName"
                  id="firstName"
                  type="text"
                  className="input bg-transparent border border-[#D2D6DA] text-[14px] rounded-[8px] focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="Alec"
                />
                <ErrorMessage name="firstName" render={renderError} />
              </div>
            </div>
            <div className="inputFormGroup">
              <FieldLabel label="Last Name" htmlFor="lastName" />
              <div className="control">
                <Field
                  name="lastName"
                  id="lastName"
                  type="text"
                  className="input bg-transparent border border-[#D2D6DA] text-[14px] rounded-[8px] focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="Thompson"
                />
                <ErrorMessage name="lastName" render={renderError} />
              </div>
            </div>
            <div className="inputFormGroup">
              <FieldLabel label="Birthday" htmlFor="datepicker" />
              <div className="flex items-end date-picker relative">
                <DatePicker
                  showIcon
                  id="datepicker"
                  selected={birthDate}
                  onChange={(date) => setBirthDate(date)}
                />
              </div>
            </div>
            <div className="inputFormGroup">
              <FieldLabel label="Email" htmlFor="email" />
              <div className="control">
                <Field
                  name="email"
                  id="email"
                  type="email"
                  readOnly
                  style={{
                    backgroundColor: "#f3f3f3", // Light grey background for read-only
                    color: "#a1a1a1", // Darker grey text for read-only
                    borderColor: "#D2D6DA",
                  }}
                  className="input bg-transparent text-[14px] rounded-[8px] focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="example@email.com"
                />

                <ErrorMessage name="email" render={renderError} />
              </div>
            </div>
            <div className="inputFormGroup">
              <FieldLabel label="Title" htmlFor="title" />
              <div className="control">
                <Field
                  name="title"
                  id="title"
                  type="text"
                  className="input bg-transparent border border-[#D2D6DA] text-[14px] rounded-[8px] focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="Project Manager"
                />
                <ErrorMessage name="title" render={renderError} />
              </div>
            </div>
            <div className="inputFormGroup">
              <FieldLabel label="Phone Number" htmlFor="phonenumber" />
              <div className="control">
                <Field
                  name="phoneNumber"
                  id="phoneNumber"
                  type="text"
                  onInput={(e) => {
                    e.currentTarget.value = e.currentTarget.value.replace(
                      /[^0-9]/g,
                      ""
                    );
                  }}
                  //   inputMode="numeric" // Add this line
                  //   pattern="[0-9]*"
                  className="input bg-transparent border border-[#D2D6DA] text-[14px] rounded-[8px] focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="+1 789 456 123"
                />
                <ErrorMessage name="phoneNumber" render={renderError} />
              </div>
            </div>
          </div>
          <div className="flex justify-end">
            {uploadSuccess && renderSuccessMsg(uploadSuccess)}
            <button
              type="submit"
              className="text-[12px] py-[12px] mt-[25px] text-[#ffffff] font-bold rounded-[8px] bg-[#157810] lg:w-[125px] capitalize"
            >
              {labels["settings_save_info"]}
            </button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default BasicInfoForm;
