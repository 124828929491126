export const getErrorText = (error) => {
    let errorText = ''
    if(error.response.data.message){
        errorText = error.response.data.message;
    }
    if(error.response.data.error){
        errorText = error.response.data.error;
    }
    if(error.response.data.errors){
        errorText = error.response.data.errors[0].message;
    }
    errorText = Array.isArray(errorText) ? errorText.join(', ') : errorText;
    return errorText.replace(/"/g, '');
}

// Chart data
export const mapSalesToChartData = (sales, monthNames) => {
    return monthNames.map((name) => ({
      name,
      uv: sales[name] ? sales[name]?.total_price / sales[name]?.orders?.length : 0,
      orders: sales[name] ? sales[name]?.orders?.length : 0,
      amt: sales[name] ? sales[name]?.total_price : 0,
      sales: sales[name] ? sales[name]?.total_price : 0,
    }));
  };