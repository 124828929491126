import { apiUrl } from "../utils/constants";
import { POST, PUT } from "./api.service.wrapper";

export const Signin = async (email, password) => {
  const response = await POST(apiUrl.login, { email, password });
  localStorage.setItem("token", response?.data?.token);
  return response;
};

export const Signup = async (payload) => {
  const response = await POST(apiUrl.validate_user, payload);
  localStorage.setItem("token", response?.data?.token);
  localStorage.setItem(`auth0.${process.env.REACT_APP_AUTH0_CLIENT_ID}`, true);
  return response;
};

export const UpdateUser = async (payload) => {
  const response = await PUT(apiUrl.update_user, payload);
  return response;
};

export const GetToken = () => {
  // return localStorage.getItem("token");
  return process.env.REACT_APP_AUTH_TOKEN;
};
