import React from "react";
import styles from "./Score.module.scss";

const Score = ({ color, icon, title, property, total }) => {
  return (
    <>
      <div className={`${styles.scoreItems} flex`}>
        <div
          style={{ backgroundColor: color ? color : "" }}
          className={`w-[40px] h-[40px] flex items-center ${
            !color ? property : ""
          } justify-center rounded`}
        >
          <img src={icon} alt={[icon]} />
        </div>
        <div className="px-[15px]">
          <p className="text-[#9197B3] text-[14px] pb-[5px]">{title}</p>
          <h6 className="text-[2A2A2A] text-[24px] font-semibold">{total}</h6>
        </div>
      </div>
    </>
  );
};

export default Score;
