import React, { useCallback, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";

import Button from "../../components/Common/Button";
import { GetGoals, associateGoals } from "../../services/goal.service";
import { useAuth } from "../../utils/user";
import SetupSideBar from "../../components/SetupSideBar";

const StepFour = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [goals, setGoals] = useState([]);
  const [selectedGoals, setSelectedGoals] = useState([]);
  const { labels } = useAuth();
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = useCallback(
    debounce(async () => {
      setLoading(true);
      const goals = await GetGoals();
      // setSelectedIndustry(industries?.data?.data[0]);
      setGoals(goals?.data?.data);
      setLoading(false);
    }, 500),
    []
  );

  const handleSelectAllGoals = () => {
    const allGoalIds = goals.map((goal) => goal.id);
    setSelectedGoals(allGoalIds);
  };

  const handleGoalSelect = (goalId) => {
    setSelectedGoals((prevSelectedGoals) =>
      prevSelectedGoals.includes(goalId)
        ? prevSelectedGoals.filter((id) => id !== goalId)
        : [...prevSelectedGoals, goalId]
    );
  };

  const handleClick = async () => {
    let shop = JSON.parse(localStorage.getItem("shop"));

    await associateGoals(shop.shop, selectedGoals);
    localStorage.removeItem("shop");
    localStorage.removeItem("next-step");
    navigate("/dashboard");
  };

  return (
    <div className="grid grid-cols-12 h-[100vh]">
      <div className="col-span-9 px-16 pt-28 relative">
        <h1 className="text-5xl font-semibold text-[#111] mb-4">
          {labels["step4_optimization_setup"]}
        </h1>
        <p className="text-xl mb-16 font-light text-[#1E1E1E]">
          {labels["step4_optimization_setup_description"]}
        </p>
        <h1 className="text-[#111] text-2xl mb-4">
          {labels["step4_question"]}
        </h1>
        <p className="text-[#6D6D6D] text-sm  mb-10">
          {labels["step4_click_all_that_apply"]}
        </p>
        <div className="grid grid-cols-4 gap-8">
          {goals?.map((goal) => {
            // Determine if this goal is selected
            const isSelected = selectedGoals.includes(goal.id);

            // Set the background color based on selection
            const backgroundColor = isSelected ? "#c8e6c9" : "#f8f8f8"; // #D6DBF5 is just an example color for selected goals

            return (
              <div
                key={goal.id}
                className={`w-[200px] h-[167px] p-1 flex items-center justify-center ${
                  isSelected ? "bg-[#D6DBF5]" : "bg-[#f8f8f8]"
                }`}
                style={{ backgroundColor }} // This line applies the background color directly
                aria-selected={isSelected}
                onClick={() => handleGoalSelect(goal.id)}
              >
                <p className="font-semibold text-xl text-center">{goal.name}</p>
              </div>
            );
          })}

          <div
            className="w-[200px] h-[167px] flex items-center justify-center bg-[#f8f8f8]"
            onClick={handleSelectAllGoals}
          >
            <p className="font-semibold text-xl text-center">All</p>
          </div>
        </div>
        {/* <div className="flex justify-end mt-20">
          <Button btntitle="Finish" btnLink={"/dashboard"} />
        </div> */}
        <div className="flex justify-end mt-20">
          <button
            onClick={handleClick}
            type="submit"
            className="text-[12px] py-[12px] my-[25px] text-[#ffffff] font-bold rounded-[8px] bg-[#157810] lg:w-[125px] capitalize"
          >
            {labels["step4_finish"]}
          </button>
          {/* <Link to={"/step-four"}>
            <Button btntitle="NEXT" />
          </Link> */}
        </div>
        <p className="absolute left-2 bottom-2 text-[#A3AED0] text-sm font-medium">
          © 2023 Thruways Co. All Rights Reserved.{" "}
        </p>
      </div>
      <SetupSideBar step={4} />
    </div>
  );
};

export default StepFour;
