// AuthContext.js
import React, { createContext, useContext, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Signup } from "../services/auth.service";
import { GetLabels } from "../services/label.service";
import { GetIcons } from "../services/icon.service";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [authUser, setAuthUser] = useState(null);
  const [authError, setAuthError] = useState(null)
  const [labels, setLabels] = useState(null)
  const [icons, setIcons] = useState(null)
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const shop = JSON.parse(localStorage.getItem("shop"))?.shop;

  useEffect(() => {
    const getLabelsAndIcons = async () => {
      const [labels, icons] = await Promise.all([GetLabels(), GetIcons()])
      setIcons(icons?.data?.data)
      setLabels(labels?.data?.data)
    }

    getLabelsAndIcons()
  },[])
  useEffect(() => {
    const handleUserSignup = async () => {
      if (isAuthenticated && user) {
        try {
          const userPayload = {
            first_name: user.given_name ?? user.nickname, // Or user.nickname depending on what you receive
            last_name: user.family_name,
            name: user.given_name ?? user.nickname + ' ' + user.family_name,
            email: user.email,
            auth0_user_id: user.user_id, // or user.sub depending on the property Auth0 uses
            auth0_email_verified: user.email_verified,
            auth0_name: user.name,
            auth0_nickname: user.nickname,
            auth0_picture: user.picture,
            auth0_sub: user.sub, // This is typically the user ID from Auth0
            mfa_enabled: false, // Default to false unless you have this info
            shop: shop ?? "NULL",
          };

          // Call your signup function
          const response = await Signup(userPayload);

          if (response.user && response.stores) {
            // Set the user data in your context
            setAuthUser({
              ...response.user,
              stores: response.stores,
              // token: response.data.token,
            });

            if(shop){
              localStorage.setItem('active_shop',shop);
            }
          }else{
            localStorage.removeItem("shop");
          }
        } catch (error) {
          localStorage.removeItem("shop");
          setAuthError('Store not found OR the store is already assigned to another user');
        }
      }
    };

    handleUserSignup();
  }, [user, isAuthenticated, getAccessTokenSilently, shop]);

  return (
    <AuthContext.Provider value={{ authUser, setAuthUser, labels, icons, authError }}>
      {children}
    </AuthContext.Provider>
  );
};

// Use this hook to access the auth context
export const useAuth = () => useContext(AuthContext);
