import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../utils/user";
import SetupSideBar from "../../components/SetupSideBar";
const StepTwo = () => {
  const { authUser, labels, icons } = useAuth();
  const navigate = useNavigate();
  const shop = localStorage.getItem("shop");

  useEffect(() => {
    if (shop && authUser) {
      // Parse the 'shop' object from localStorage
      const parsedShop = JSON.parse(shop);
      // Check if the shop key does notexists in authUser.stores
      if (
        authUser.stores &&
        authUser.stores.some(
          (store) => store.url === parsedShop.shop && store.industry_id !== null
        )
      ) {
        localStorage.removeItem("shop");
        navigate("/dashboard");
      }
    }
  }, [authUser, navigate]);

  if(!shop || !authUser){
    return '';
  }

  return (
    <div className="grid grid-cols-12 h-[100vh]">
      <div className="col-span-9 px-16 pt-28 relative">
        <h1 className="text-5xl font-semibold text-[#111] mb-4">
          {labels['step2_connect_your_platform']}
        </h1>
        <p className="text-xl mb-16 font-light text-[#1E1E1E]">
          {labels['step2_thruways_is_currently_available']}
        </p>
        <div className="grid grid-cols-4 gap-8">
          <Link to={"/step-three"}>
            <div className="w-[200px] h-[167px] flex items-center justify-center bg-[#c8e6c9]">
              <img src={icons['shopify_logo_black+1.png']} alt="logo" />
            </div>
          </Link>
          <Link to="#" className="pointer-events-none">
            <div className="w-[200px] h-[167px] flex items-center justify-center bg-[#f8f8f8] opacity-50 cursor-not-allowed">
              <img src={icons['woocommerce-logo-color-black+1.png']} alt="logo" />
            </div>
          </Link>
          <Link to="#" className="pointer-events-none">
            <div className="w-[200px] h-[167px] flex items-center justify-center bg-[#f8f8f8] opacity-50 cursor-not-allowed">
              <img src={icons['BigCommerce-logo-dark+1.png']} alt="logo" />
            </div>
          </Link>
          <Link to="#" className="pointer-events-none">
            <div className="w-[200px] h-[167px] flex items-center justify-center bg-[#f8f8f8] opacity-50 cursor-not-allowed">
              <p className="font-semibold text-xl">{labels['step2_other']}</p>
            </div>
          </Link>
        </div>
        <p className="absolute left-2 bottom-2 text-[#A3AED0] text-sm font-medium">
          © 2023 Thruways Co. All Rights Reserved.{" "}
        </p>
      </div>
      <SetupSideBar step={2}/>
    </div>
  );
};

export default StepTwo;
