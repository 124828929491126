import React, { createContext, useContext, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const StepOneInitialization = createContext();

export const useStepOneInit = () => useContext(StepOneInitialization);

const useShopInitialization = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [shop, setShop] = useState(null);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const shopQuery = query.get("shop");
    const platform = query.get("platform");
    const action = query.get("action");

    if (shopQuery && location.pathname === "/step-one") {
      setShop(shopQuery);
      localStorage.setItem("shop", JSON.stringify({ shop: shopQuery, platform, action }));

      const loggedIn = localStorage.getItem(`auth0.${process.env.REACT_APP_AUTH0_CLIENT_ID}`);
      if (loggedIn) navigate("/step-two");
    }
  }, [location, navigate]);

  return shop;
};

const useRedirectIfShopExists = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (['/dashboard', '/recommendation', '/settings', '/analytics'].includes(location.pathname)) {
      const shop = localStorage.getItem("shop");
      if (shop) {
        const nextStep = localStorage.getItem("next-step") || 'step-two';
        navigate(`/${nextStep}`);
      }
    }
  }, [location, navigate]);
};

export const StepOneInitProvider = ({ children }) => {
  const shop = useShopInitialization();
  useRedirectIfShopExists();

  return (
    <StepOneInitialization.Provider value={shop}>
      {children}
    </StepOneInitialization.Provider>
  );
};
