import React from "react";
import { Listbox, Transition } from "@headlessui/react";
import { Fragment } from "react";

const StoreDropdown = ({
  selectedStore,
  handleStoreSelect,
  stores,
  icons,
  labels,
}) => {
  return (
    <Listbox value={selectedStore} onChange={handleStoreSelect}>
      <div className="relative mt-1">
        <Listbox.Button className="text-[#2A2A2A] text-[20px] font-semibold relative w-full cursor-pointer rounded-lg bg-white py-2 pl-3 pr-10 text-left">
          <span className="block truncate ">{selectedStore.name}</span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <img src={icons["angle-down.svg"]} alt="chevron" />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="z-10 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {stores.length === 0 ? (
              <Listbox.Option
                className="relative cursor-pointer select-none py-2 px-5 text-gray-900 flex items-center"
                value={{
                  name: "Shopify" /* other properties if needed */,
                }}
              >
                <img
                  src="/shopify.png"
                  alt="Shopify"
                  style={{
                    width: "80px",
                    height: "50px",
                    marginRight: "8px",
                  }}
                />
                <span className="font-normal">
                  {labels["settings_shopify"]}
                </span>
              </Listbox.Option>
            ) : (
              stores.map((store, storeIdx) => (
                <Listbox.Option
                  key={storeIdx}
                  className={({ active }) =>
                    `relative cursor-pointer select-none py-2 px-5 ${
                      active ? "bg-amber-100 text-amber-900" : "text-gray-900"
                    }`
                  }
                  value={store}
                >
                  {({ selectedStore }) => (
                    <>
                      <span
                        className={`block ${
                          selectedStore ? "font-medium" : "font-normal"
                        }`}
                      >
                        {store.name}
                      </span>
                    </>
                  )}
                </Listbox.Option>
              ))
            )}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};

export default StoreDropdown;