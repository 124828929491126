import React from "react";

const CardBackContent = ({
  color,
  cardTitle,
  howToImplement,
  labels,
  handleButtonClick,
}) => (
  <div className="cardbody px-[25px] py-[20px]">
    <h5 className="text-[22px] font-medium">{cardTitle}</h5>
    <div className="w-[100%]">
      <div className="mt-[10px] mb-[20px]">
        <h5 className="text-[14px] font-semibold">
          {labels["recommendations_how_to_implement"]}
        </h5>
        <p className="text-[12px] font-regular">{howToImplement}</p>
      </div>
      <div className="flex justify-end">
        <button
          type="button"
          onClick={handleButtonClick}
          className={`capitalize w-[30%] font-[600] text-[#fff] bg-[#157810] text-[14px] rounder-[5px] p-[10px] rounded-[8px]`}
        >
          {labels["recommendations_back"]}
        </button>
      </div>
    </div>
  </div>
);

export default CardBackContent;
