import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import AWS from "aws-sdk";
import "react-datepicker/dist/react-datepicker.css";
import shopify from "../../shared/assets/images/shopify.png";
import analytics from "../../shared/assets/images/google-analytics.png";
import { Switch } from "@headlessui/react";
import FilledBadge from "../../components/Common/Badge/FilledBadge";
import BasicInfoForm from "../../components/Forms/BasicInfoForm";
import BusinessInfoForm from "../../components/Forms/BusinessInfoForm";
import ChangePassForm from "../../components/Forms/ChangePassForm";
import { useAuth } from "../../utils/user";
import { useActiveItem } from "../../contexts/ActiveTabContext";
import { UpdateUser } from "../../services/auth.service";

const Setting = () => {
  const { handleItemClick } = useActiveItem();
  const { authUser, setAuthUser, labels, icons } = useAuth();
  const [image, setImage] = useState(null);
  const [shouldUpload, setShouldUpload] = useState(false);
  const [imageProperties, setImageProperties] = useState(null);

  const [imageUrl, setImageUrl] = useState(null);
  const [imageError, setImageError] = useState(null);
  const [imageSuccess, setImageSuccess] = useState(null);

  useEffect(() => {
    handleItemClick("item4");
    const image = authUser?.auth0_picture
      ? authUser?.auth0_picture
      : icons["customer.png"];
    setImageUrl(image);
  }, [authUser]);


  useEffect(() => {
    let timer;

    if (imageSuccess) {
      timer = setTimeout(() => {
        setImageSuccess(null);
      }, 4000); // 4000 milliseconds = 4 seconds
    }

    // Clear the timer if the component unmounts or if imageSuccess changes again
    return () => clearTimeout(timer);
  }, [imageSuccess]); // This effect runs every time imageSuccess changes

  const uploadFile = async () => {

    if(!shouldUpload){
      setShouldUpload(true);
      return;
    }
    setImageError("");

    const MAX_ALLOWED_SIZE = labels["settings_image_size_in_kbs"]
      ? labels["settings_image_size_in_kbs"] * 1024
      : 880 * 1024; // 880k

    if (!image) {
      setImageError("No file selected");
      return;
    }
    console.log(imageProperties.size);
    if (
      imageProperties.type !== "image/png" &&
      imageProperties.type !== "image/jpeg"
    ) {
      setImageError("Only PNG or JPEG files are allowed");
      return;
    }

    if (imageProperties.size > MAX_ALLOWED_SIZE) {
      setImageError(
        "Image size should be less than " + MAX_ALLOWED_SIZE + "Kb"
      );
      return;
    }

    // S3 Bucket Name
    const S3_BUCKET = process.env.REACT_APP_S3_BUCKET_NAME;

    // S3 Region
    const REGION = process.env.REACT_APP_S3_REGION;

    // S3 Credentials
    AWS.config.update({
      accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY,
    });
    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });

    // Files Parameters

    const params = {
      Bucket: S3_BUCKET,
      Key: imageProperties.name,
      Body: imageProperties,
    };

    // Uploading file to s3

    var upload = s3
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        // File uploading progress
        console.log(
          "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
        );
      })
      .promise();

    const response = await upload;
    const imageUrl = `https://${S3_BUCKET}.s3.amazonaws.com${response?.$response?.request?.httpRequest?.path}`;
    setImageUrl(imageUrl);

    const paylaod = {
      auth0_picture: imageUrl,
      user_id: authUser?.id,
    };

    if (await UpdateUser(paylaod)) {
      setAuthUser((prevValues) => ({
        ...prevValues,
        auth0_picture: imageUrl,
      }));
    }

    setShouldUpload(false);
    setImage(null);
    setImageSuccess('Image updated!');
  };

  const resetImage = async () => {
    const paylaod = {
      auth0_picture: null,
      user_id: authUser?.id,
    };

    if (await UpdateUser(paylaod)) {
      setAuthUser((prevValues) => ({
        ...prevValues,
        auth0_picture: null,
      }));
    }
    setImageUrl(icons["customer.png"]);
  };

  const handleFileInput = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        setImage(event.target.result);
      };

      reader.readAsDataURL(file);
    }
    setImageProperties(file);
  };
console.log(shouldUpload);
  return (
    <>
      {authUser ? (
        <div className="lg:w-[857px] m-auto">
          <div
            id="profile"
            className="flex items-center mb-[24px] bg-[#fff] justify-between shadow-[0px_20px_27px_0_rgba(0,0,0,5%)] rounded-[16px] px-[20px] py-[16px]"
          >
            <div className="flex">
              {/* <div className="w-[74px]  max-h-auto"> */}
              <img
                // src={imageUrl}
                // alt={imageUrl}
                src={imageUrl}
                alt={imageUrl}
                style={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "0.25rem",
                  objectFit: "cover",
                }}
              />
              {/* </div> */}
              <div className="flex flex-col px-[20px]">
                <h6 className="text-[#2A2A2A] text-[20px] font-bold">{`${
                  authUser?.first_name
                } ${authUser?.last_name ?? ""}`}</h6>
                <p className="text-[14px] text-[#9197B3] font-semibold">
                  {labels["settings_owner"]}
                </p>
              </div>
            </div>
            <div className="flex flex-col items-center justify-between">
              <div className="flex gap-3">
              {imageSuccess && (
                <p className="text-xs text-green-500">{imageSuccess}</p>
              )}
                {shouldUpload && (
                  <>
                    <input
                      type="file"
                      className="form-control"
                      onChange={(e) => handleFileInput(e)}
                      accept="image/*"
                      hidden
                      id="upload"
                    />
                    <label
                      className="upload-btn cursor-pointer bg-[#157810] font-semibold text-[14px] text-[#fff] px-[20px] py-[10px] rounded-[8px]"
                      for="upload"
                    >
                      Choose file
                    </label>
                  </>
                )}

                {image && (
                  <div>
                    <img
                      src={image}
                      alt="Uploaded preview"
                      style={{
                        width: "42px",
                        height: "42px",
                        borderRadius: "0.25rem",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                )}
                <button
                  onClick={uploadFile}
                  className="w-[168px] bg-[#157810] font-semibold text-[14px] text-[#fff] px-[20px] py-[10px] rounded-[8px]"
                >
                  {shouldUpload ? "Update this photo" : "Upload a new photo"}
                </button>
                <button
                  onClick={resetImage}
                  className="w-[75px] font-semibold text-[14px] text-[#2A2A2A] border border-[#2A2A2A]  px-[20px] py-[10px] rounded-[8px]"
                >
                  Reset
                </button>
              </div>
              {imageError && (
                <p className="text-xs text-red-500">{imageError}</p>
              )}
              <p className="text-[#9197B3] text-[12px] font-light">
                {labels["settings_image_validation_text"]}
                {labels["settings_image_size_in_kbs"]}k
              </p>
            </div>
          </div>
          <BasicInfoForm />
          <BusinessInfoForm />
          {/* <ChangePassForm /> */}

          <div
            id="accounts"
            className="bg-[#fff] mb-[24px] shadow-[0px_20px_27px_0_rgba(0,0,0,5%)] rounded-[16px] px-[24px] py-[16px]"
          >
            <div className="pb-[32px]  pt-[24px]">
              <h2 className="text-[#2A2A2A] text-[20px] font-semibold ">
                {labels?.settings_integrations}
              </h2>
              <p className="text-[#2A2A2A] text-[14px]">
                Manage your integration settings.
              </p>
            </div>
            <div className="flex w-full">
              <div className="w-[48px] max-h-auto mr-[15px]">
                <img src={shopify} alt="shopify" />
              </div>
              <div className="w-full">
                <div className="flex justify-between items-center">
                  <div className="">
                    <h6 className="text-[#2A2A2A] text-[20px] font-semibold">
                      Shopify
                    </h6>
                    {/* <p className="text-[#9197B3] text-[14px]">Show less</p> */}
                  </div>
                </div>
                <p className="text-[#9197B3] text-[14px] mt-[16px] mb-[24px]">
                  Thruways analyzes the performance of your Shopify store and
                  provides actionable insights and recommendations on how to
                  drive more sales. Connect your Shopify store to Thruways!
                </p>
                {authUser &&
                  authUser.stores &&
                  authUser.stores.map((store) => (
                    <div className="flex justify-between bg-[#F8F9FA] items-center rounded-[8px]">
                      <p className="text-[#9197B3] text-[14px] font-semibold px-[10px]">
                        {" "}
                        Connected account
                      </p>
                      <div className="flex py-[12px] px-[16px] items-center">
                        <p className="text-[#2A2A2A] text-[14px] font-semibold px-[10px]">
                          {" "}
                          http://{store.url}
                        </p>
                        <button
                          onClick={() => {
                            window.open(
                              `https://${store.url}/admin/settings/apps?tab=installed`,
                              "_blank"
                            );
                          }}
                          type="button"
                          className={`text-[#fff] text-[12px] ${
                            store.api_token !== null
                              ? "bg-[#F7284A]"
                              : "bg-[#008000]"
                          } py-[7px] px-[10px] w-[105px] rounded-[8px] font-bold`}
                        >
                          {store.api_token !== null ? "Disconnect" : "Connect"}
                        </button>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <hr className="mt-[20px] mb-[15px]"></hr>
            {/* google analytics */}
            <div className="w-full flex justify-between items-center">
              <div className="w-[48px] max-h-auto mr-[15px]">
                <img src={analytics} alt="analytics" />
              </div>
              <div className="w-full">
                <div className="flex justify-between items-center w-full">
                  <div className="">
                    <h6 className="text-[#2A2A2A] text-[20px] font-semibold">
                      {" "}
                      Google Analytics{" "}
                    </h6>
                    {/* <p className='text-[#9197B3] text-[14px]'>Show less</p> */}
                  </div>
                  <div className="flex">
                    <p className="text-[14px] text-[#8392AB] px-[10px]">
                      Coming Soon!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Notification */}
          {/* <div
            id="notification"
            className="bg-[#fff] mb-[24px] shadow-[0px_20px_27px_0_rgba(0,0,0,5%)] rounded-[16px] px-[24px] py-[16px]"
          >
            <div className="pb-[32px]  pt-[24px]">
              <h2 className="text-[#2A2A2A] text-[20px] font-semibold ">
                Notifications
              </h2>
              <p className="text-[#2A2A2A] text-[14px]">
                Choose how you receive notifications. These notification
                settings apply to the things you’re watching.
              </p>
            </div>
            <table class="table-auto w-full">
              <thead>
                <tr>
                  <th className="text-[#2A2A2A] text-[16px] font-[400] py-[16px] text-left">
                    Activity
                  </th>
                  <th className="text-[#2A2A2A] text-[16px] font-[400] py-[16px] text-left">
                    Email
                  </th>
                  <th className="text-[#2A2A2A] text-[16px] font-[400] py-[16px] text-left">
                    SMS
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="py-[10px] border-t border-[#E9ECEF]">
                    <h6 className="text-[14px] text-[#2A2A2A] mb-[5px]">
                      New Recommendations
                    </h6>
                    <p className="text-[12px] text-[#9197B3]">
                      Notify when new recommendations are added to my account.{" "}
                    </p>
                  </td>
                  <td className="border-t border-[#E9ECEF]">
                    <Switch
                      checked={recom}
                      onChange={setRecom}
                      className={`${
                        recom ? "bg-[#157810]" : "bg-[#E9ECEF]"
                      } relative inline-flex h-6 w-11 items-center rounded-full`}
                    >
                      <span className="sr-only">Enable notifications</span>
                      <span
                        className={`${
                          recom ? "translate-x-6" : "translate-x-1"
                        } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                      />
                    </Switch>
                  </td>
                  <td className="border-t border-[#E9ECEF]">
                    <Switch
                      checked={sms}
                      onChange={setSms}
                      className={`${
                        sms ? "bg-[#157810]" : "bg-[#E9ECEF]"
                      } relative inline-flex h-6 w-11 items-center rounded-full`}
                    >
                      <span className="sr-only">Enable notifications</span>
                      <span
                        className={`${
                          sms ? "translate-x-6" : "translate-x-1"
                        } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                      />
                    </Switch>
                  </td>
                </tr>
                <tr>
                  <td className="py-[10px] border-t border-[#E9ECEF]">
                    <h6 className="text-[14px] text-[#2A2A2A] mb-[5px]">
                      Updated Optimization Score
                    </h6>
                    <p className="text-[12px] text-[#9197B3]">
                      Notify when there is an update to my optimization score.
                    </p>
                  </td>
                  <td className="border-t border-[#E9ECEF]">
                    <Switch
                      checked={optScore}
                      onChange={setOptScore}
                      className={`${
                        optScore ? "bg-[#157810]" : "bg-[#E9ECEF]"
                      } relative inline-flex h-6 w-11 items-center rounded-full`}
                    >
                      <span className="sr-only">Enable notifications</span>
                      <span
                        className={`${
                          optScore ? "translate-x-6" : "translate-x-1"
                        } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                      />
                    </Switch>
                  </td>
                  <td className="border-t border-[#E9ECEF]">
                    <Switch
                      checked={smsOpt}
                      onChange={setSmsOpt}
                      className={`${
                        smsOpt ? "bg-[#157810]" : "bg-[#E9ECEF]"
                      } relative inline-flex h-6 w-11 items-center rounded-full`}
                    >
                      <span className="sr-only">Enable notifications</span>
                      <span
                        className={`${
                          smsOpt ? "translate-x-6" : "translate-x-1"
                        } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                      />
                    </Switch>
                  </td>
                </tr>
                <tr>
                  <td className="py-[10px] border-t border-[#E9ECEF]">
                    <h6 className="text-[14px] text-[#2A2A2A] mb-[5px]">
                      Alerts
                    </h6>
                    <p className="text-[12px] text-[#9197B3]">
                      Notify me when there are alerts requiring immediate
                      attention.
                    </p>
                  </td>
                  <td className="border-t border-[#E9ECEF]">
                    <Switch
                      checked={alert}
                      onChange={setAlert}
                      className={`${
                        alert ? "bg-[#157810]" : "bg-[#E9ECEF]"
                      } relative inline-flex h-6 w-11 items-center rounded-full`}
                    >
                      <span className="sr-only">Enable notifications</span>
                      <span
                        className={`${
                          alert ? "translate-x-6" : "translate-x-1"
                        } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                      />
                    </Switch>
                  </td>
                  <td className="border-t border-[#E9ECEF]">
                    <Switch
                      checked={alertSms}
                      onChange={setAlertSms}
                      className={`${
                        alertSms ? "bg-[#157810]" : "bg-[#E9ECEF]"
                      } relative inline-flex h-6 w-11 items-center rounded-full`}
                    >
                      <span className="sr-only">Enable notifications</span>
                      <span
                        className={`${
                          alertSms ? "translate-x-6" : "translate-x-1"
                        } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                      />
                    </Switch>
                  </td>
                </tr>
                <tr>
                  <td className="py-[10px] border-t border-[#E9ECEF]">
                    <h6 className="text-[14px] text-[#2A2A2A] mb-[5px]">
                      New Device Login
                    </h6>
                    <p className="text-[12px] text-[#9197B3]">
                      Notify when there is a login from a new device
                    </p>
                  </td>
                  <td className="border-t border-[#E9ECEF]">
                    <Switch
                      checked={loginEmail}
                      onChange={setLoginEmail}
                      className={`${
                        loginEmail ? "bg-[#157810]" : "bg-[#E9ECEF]"
                      } relative inline-flex h-6 w-11 items-center rounded-full`}
                    >
                      <span className="sr-only">Enable notifications</span>
                      <span
                        className={`${
                          loginEmail ? "translate-x-6" : "translate-x-1"
                        } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                      />
                    </Switch>
                  </td>
                  <td className="border-t border-[#E9ECEF]">
                    <Switch
                      checked={loginSms}
                      onChange={setLoginSms}
                      className={`${
                        loginSms ? "bg-[#157810]" : "bg-[#E9ECEF]"
                      } relative inline-flex h-6 w-11 items-center rounded-full`}
                    >
                      <span className="sr-only">Enable notifications</span>
                      <span
                        className={`${
                          loginSms ? "translate-x-6" : "translate-x-1"
                        } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                      />
                    </Switch>
                  </td>
                </tr>
              </tbody>
            </table>
          </div> */}
          {/* Notification */}

          {/* Sessions */}
          {/* <div
        id="sessions"
        className="bg-[#fff] first-letter: none mb-[24px] shadow-[0px_20px_27px_0_rgba(0,0,0,5%)] rounded-[16px] px-[24px] py-[16px]"
      >
        <div className="pb-[32px]  pt-[24px]">
          <h2 className="text-[#2A2A2A] text-[20px] font-semibold ">
            Sessions
          </h2>
          <p className="text-[#2A2A2A] text-[14px]">
            This is a list of devices that have logged into your account. Remove
            those that you do not recognize.
          </p>
        </div>
        <ul className="pl-[35px]">
          <li className="flex justify-between items-center py-[10px] border-t border-[#E9ECEF]">
            <div className="">
              <h6 className="text-[14px] text-[#2A2A2A] mb-[5px]">
                {" "}
                Bucharest 68.133.163.201{" "}
              </h6>
              <p className="text-[12px] text-[#9197B3]">
                Your current session{" "}
              </p>
            </div>
            <div className="flex items-center">
              <FilledBadge title="active" />
              <span className="text-[14px] text-[#9197B3] px-[10px]">EU</span>
              <button className="text-[14px] text-[#F7284A]">See More</button>
            </div>
          </li>
          <li className="flex justify-between items-center py-[10px] border-t border-[#E9ECEF]">
            <div className="">
              <h6 className="text-[14px] text-[#2A2A2A] mb-[5px]">
                Chrome on macOS{" "}
              </h6>
            </div>
            <div className="flex items-center">
              <span className="text-[14px] text-[#9197B3] px-[10px]">EU</span>
              <button className="text-[14px] text-[#F7284A]">See More</button>
            </div>
          </li>
          <li className="flex justify-between items-center py-[10px] border-t border-[#E9ECEF]">
            <div className="">
              <h6 className="text-[14px] text-[#2A2A2A] mb-[5px]">
                Safari on iPhone
              </h6>
            </div>
            <div className="flex items-center">
              <span className="text-[14px] text-[#9197B3] px-[10px]">EU</span>
              <button className="text-[14px] text-[#F7284A]">See More</button>
            </div>
          </li>
        </ul>
      </div> */}
          {/* Sessions */}
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Setting;
