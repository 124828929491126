import React from "react";
import styles from "./menu.module.scss";
import Logo from "../../Common/Logo";
import { Link } from "react-router-dom";
import Footer from "../../Footer/Footer";
import { HashLink } from "react-router-hash-link";
import LogoutButton from "../../Common/LogoutButton";
import { useActiveItem } from "../../../contexts/ActiveTabContext";
import { useAuth } from "../../../utils/user";

const Menu = () => {
  const { activeItem, handleItemClick } = useActiveItem();
  const { icons, labels } = useAuth();

  return (
    <div
      className={`${styles.menuMainWrapper} overflow-auto h-[100vh] lg:px-[30px] px-[15px] box-border py-[17px] bg-[#F9F9F9] lg:w-[330px] w-[100px]`}
    >
      <div className="flex flex-col justify-between h-[100%]">
        <div>
          <div className="flex justify-center">
            <Logo />
          </div>
          <ul className="py-[28px]">
            <li
              className={`menu pb-[35px] text-[#9197B3] text-[14px] font-medium bg-[#f9f9f9] ${
                activeItem === "item1" || activeItem == null ? "active" : ""
              }`}
            >
              <Link
                to="/dashboard"
                className="flex lg:justify-start justify-center capitalize items-center bg-[#f9f9f9] z-[] "
                onClick={() => handleItemClick("item1")}
              >
                <img src={icons["dashboard-icon.svg"]} alt="" />
                <span className="px-[15px] lg:block hidden">{labels['menu_dashboard']} </span>
              </Link>
            </li>
            <li
              className={`menu pb-[35px] text-[#9197B3] text-[14px] font-medium bg-[#f9f9f9]  ${
                activeItem === "item2" ? "active" : ""
              }`}
            >
              <Link
                to="/recommendation"
                className="flex lg:justify-start justify-center capitalize items-center bg-[#f9f9f9] z-[]"
                onClick={() => handleItemClick("item2")}
              >
                <img src={icons["recommendation.svg"]} alt="" />
                <span className="px-[15px] lg:block hidden">
                  {labels['menu_recommendations']}
                </span>
              </Link>
            </li>
            <li
              className={`menu pb-[35px] text-[#9197B3] text-[14px] font-medium bg-[#f9f9f9]  ${
                activeItem === "item3" ? "active" : ""
              }`}
            >
              <Link
                to="/analytics"
                className="flex lg:justify-start justify-center capitalize items-center bg-[#f9f9f9] z-[]"
                onClick={() => handleItemClick("item3")}
              >
                <img src={icons["analytics.svg"]} alt="" />
                <span className="px-[15px] lg:block hidden">{labels['menu_analytics']} </span>
              </Link>
            </li>
            <li
              className={`menu pb-[35px] text-[#9197B3] text-[14px] font-medium bg-[#f9f9f9]  ${
                activeItem === "item4" ? "active" : ""
              }`}
            >
              <Link
                to="/settings"
                className="flex lg:justify-start justify-center capitalize items-center bg-[#f9f9f9] relative z-[10] "
                onClick={() => handleItemClick("item4")}
              >
                <img src={icons["settings.svg"]} alt="" className="" />
                <span className="px-[15px] lg:block hidden ">{labels['menu_settings']} </span>
              </Link>
              {/* <ul
                className={`${styles.sublistsettings} ${
                  activeItem === "item4" ? "block" : "hidden"
                }`}
              >
                <li className="relative h-[38px] cursor-pointer  top-[0px] left-[10px] flex align-baseline">
                  <HashLink
                    smooth
                    to="/settings#profile"
                    className="cursor-pointer top-[0px] relative flex flex-col justify-end items-baseline capitalize items-center px-[25px]"
                  >
                    <span className="px-[15px] lg:block hidden">{labels['menu_profile']} </span>
                  </HashLink>
                </li>
                <li className="relative h-[38px] cursor-pointer  top-[-5px]  left-[10px] flex align-baseline">
                  <HashLink
                    smooth
                    to="/settings#basicinfo"
                    className="cursor-pointer top-[0px] relative flex flex-col justify-end items-baseline  capitalize items-center px-[25px]"
                  >
                    <span className="px-[15px] lg:block hidden">
                      {labels['menu_basic_info']}
                    </span>
                  </HashLink>
                </li> */}
                {/* <li className="relative h-[38px] cursor-pointer  top-[-7px] left-[10px] flex align-baseline">
                  <HashLink
                    smooth
                    to="/settings#changePass"
                    className="cursor-pointer  top-[0px] relative flex flex-col justify-end items-baseline capitalize items-center px-[25px]"
                  >
                    <span className="px-[15px] lg:block hidden">
                      Change Password
                    </span>
                  </HashLink>
                </li> */}
                {/* <li className="relative h-[38px] cursor-pointer  top-[-9px] left-[10px] flex align-baseline">
                  <HashLink
                    smooth
                    to="/settings#accounts"
                    className="cursor-pointer top-[0px] relative flex  flex-col justify-end items-baseline capitalize items-center px-[25px]"
                  >
                    <span className="px-[15px] lg:block hidden">{labels['menu_accounts']} </span>
                  </HashLink>
                </li> */}
                {/* <li className="relative h-[38px] cursor-pointer  top-[-12px]  left-[10px] flex align-baseline">
                  <HashLink
                    smooth
                    to="/settings#notification"
                    className="cursor-pointer flex top-[0px] relative  flex-col justify-end items-baseline capitalize items-center px-[25px]"
                  >
                    <span className="px-[15px] lg:block hidden">
                      Notifications
                    </span>
                  </HashLink>
                </li>
                <li className="relative h-[38px] cursor-pointer  top-[-14px]  left-[10px] flex align-baseline">
                  <HashLink
                    smooth
                    to="/settings#sessions"
                    className="pt-[28px] flex top-[0px] relative flex-col justify-end items-baseline capitalize items-center px-[25px]"
                  >
                    <span className="px-[15px] lg:block hidden">Sessions </span>
                  </HashLink>
                </li> */}
              {/* </ul> */}
            </li>
          </ul>
        </div>
        <div>
          <ul className="">
            <li
              className={`menu pb-[25px]  text-[#9197B3] text-[14px] font-medium  bg-[#f9f9f9]`}
              onClick={() => handleItemClick("item5")}
            >
              <Link
                to="https://help.thruways.co"
                target="_blank"
                className="flex lg:justify-start justify-center capitalize items-center bg-[#f9f9f9] z-[]"
              >
                <img src={icons["support.svg"]} alt="" />
                <span className="px-[15px] lg:block hidden">{labels['menu_support']}</span>
              </Link>
            </li>
            <li
              className={`menu pb-[25px] text-[#9197B3] text-[14px] font-medium bg-[#f9f9f9] ${
                activeItem === "item6" ? "active" : ""
              }`}
              onClick={() => handleItemClick("item6")}
            >
              <LogoutButton />
            </li>
          </ul>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Menu;
