import React, { createContext, useState, useContext } from 'react';

const ActiveStoreContext = createContext();

export const useActiveStore = () => useContext(ActiveStoreContext);

export const ActiveStoreProvider = ({ children }) => {
  const [activeStore, setActiveStore] = useState(null);

  const handleStoreClick = (Store) => {
    setActiveStore(Store);
  };

  return (
    <ActiveStoreContext.Provider value={{ activeStore, handleStoreClick }}>
      {children}
    </ActiveStoreContext.Provider>
  );
};
