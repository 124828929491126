import {apiUrl} from "../utils/constants";
import {GET} from "./api.service.wrapper";

export const GetStats = async (params) => {
  const response = await GET(apiUrl.orderStats, params);
  return response;
};

export const GetAnalytics = async (params) => {
  const response = await GET(apiUrl.analytics, params);
  return response;
};