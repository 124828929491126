import React from "react";
import { useAuth } from "../../utils/user";

const Footer = () => {
  const { labels } = useAuth();
  return (
    <h6 className="text-[14px] text-[#9A9A9A] pt-0 pb-[20px] lg:block hidden">
      {labels["footer_text"]}
    </h6>
  );
};

export default Footer;
