import React, { useState, useEffect } from "react";
import Icons from "../Icons";

const SalesCard = ({
  totalInc,
  totalOrd,
  cardIcon,
  title,
  desc,
  totalSale,
  totalOrders,
  orderTotal,
}) => {
  const [totalIncColor, setTotalIncColor] = useState("");
  const [totalIncIcon, setTotalIncIcon] = useState("");
  const [totalOrdColor, setTotalOrdColor] = useState("");
  const [totalOrdIcon, setTotalOrdIcon] = useState("");

  const setColorIconBasedOnValue = (value) => {
    return value.includes("-")
      ? ["red", "icon-arrow-down"]
      : ["green", "icon-arrow-up"];
  };

  useEffect(() => {
    const [color, icon] = setColorIconBasedOnValue(totalInc);
    setTotalIncColor(color);
    setTotalIncIcon(icon);
  }, [totalInc]);

  useEffect(() => {
    const [color, icon] = setColorIconBasedOnValue(totalOrd);
    setTotalOrdColor(color);
    setTotalOrdIcon(icon);
  }, [totalOrd]);

  return (
    <div>
      <div className="flex-1 mb-[10px] lg:mr-[15px] bg-[#fff] shadow-[10px_0_60px_0_rgba(226,236,249,0.5)] px-[15px] py-[20px]  rounded-[8px]">
        <div className="flex items-center">
          <img src={cardIcon} alt={[cardIcon]} />
          <h6 className="pl-[15px] text-[14px] font-bold text-[#000]">
            {title}
          </h6>
        </div>
        <div className="flex">
          <div className="">
            <p className="mt-[10px] py-[5px] text-[#9197B3] text-[12px] font-normal items-center flex">
              {desc}
            </p>
            <div className="flex">
              <h2 className="text-[#2A2A2A] text-[25px] font-[700]">
                {totalSale}
              </h2>
              {/* <p className="self-end flex text-[#157810] text-[12px]  px-[8px]"> */}
              <p
                style={{ color: totalIncColor }}
                className="self-end flex text-[12px]  px-[8px]"
              >
                {/* <span className="inline-flex text-[13px] font-bold rotate-[180deg]"> */}
                <span
                  className={`${
                    totalInc.includes("-")
                      ? "inset-y-0 right-0 flex items-center"
                      : "inline-flex text-[13px] font-bold rotate-[180deg]"
                  }`}
                >
                  {" "}
                  <Icons iconName={totalIncIcon} className="font-bold " />{" "}
                </span>{" "}
                {totalInc}
              </p>
            </div>
          </div>
          <div className="">
            <p className="mt-[10px] py-[5px] text-[#9197B3] text-[12px] font-normal items-center flex">
              {orderTotal}
            </p>
            <div className="flex">
              <h2 className="text-[#2A2A2A] text-[25px] font-[700]">
                {totalOrders}
              </h2>
              <p
                style={{ color: totalOrdColor }}
                className="self-end flex text-[12px] font-normal px-[8px]"
              >
                {/* <span className="inline-flex text-[13px] font-bold rotate-[180deg]"> */}
                <span
                  className={`${
                    totalInc.includes("-")
                      ? "inset-y-0 right-0 flex items-center"
                      : "inline-flex text-[13px] font-bold rotate-[180deg]"
                  }`}
                >
                  {" "}
                  <Icons iconName={totalOrdIcon} className="font-bold " />{" "}
                </span>{" "}
                {totalOrd}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesCard;
