import React, { useCallback, useState, useMemo } from "react";
import { PieChart, Pie } from "recharts";
import renderActiveShape from "./renderActiveShape";

export default function RecommendChart({
  issueCount,
  warningCount,
  opportunityCount,
  issueData,
  warningData,
  opportunityData,
  recommendationsCount,
}) {
  const [activeIndex, setActiveIndex] = useState(0);
  const onPieEnter = useCallback((_, index) => {
    setActiveIndex(index);
  }, []);

  const data = useMemo(() => {
    if (issueCount || warningCount || opportunityCount) {
      return [
        {
          name: issueData[0]?.type || "Group A",
          value: issueCount * 100,
          fill: issueData[0]?.background_color || "#2F69FF",
        },
        {
          name: warningData[0]?.type || "Group B",
          value: warningCount * 100,
          fill: warningData[0]?.background_color || "#E7B416",
        },
        {
          name: opportunityData[0]?.type || "Group C",
          value: opportunityCount * 100,
          fill: opportunityData[0]?.background_color || "#F7284A",
        },
      ];
    }

    return [
      {
        name: "No Recommendations",
        value: 0.00001,
        fill: "#A9A9A9",
      },
    ];
  }, [
    issueCount,
    warningCount,
    opportunityCount,
    issueData,
    warningData,
    opportunityData,
  ]);

  return (
    <PieChart className="pie-chart" width={460} height={300}>
      <Pie
        activeIndex={activeIndex}
        activeShape={renderActiveShape(recommendationsCount)}
        data={data}
        cx={210}
        cy={150}
        innerRadius={100}
        outerRadius={145}
        fill="#8884d8"
        dataKey="value"
        onMouseEnter={onPieEnter}
      />
    </PieChart>
  );
}
