import React from "react";
import { Link } from "react-router-dom";
import Button from "../../components/Common/Button";
import { useStepOneInit } from "../../contexts/StepOneContext";
import SetupSideBar from "../../components/SetupSideBar";

const StepOne = () => {
  const shop = useStepOneInit();

  return (
    <div className="grid grid-cols-12 h-[100vh]">
      <div className="col-span-9 px-16 pt-28 relative">
        <h1 className="text-5xl font-semibold text-[#111] mb-4">Sign Up</h1>
        <p className="text-xl mb-16 font-light text-[#1E1E1E]">
          Sign up easily using your email and password or with Google
        </p>

        {shop === null || shop === "" ? (
          <Button btntitle={"Connect a platform first"} />
        ) : (
          <Link to="/step-two">
            <Button btntitle="Sign Up" />
          </Link>
        )}
        <p className="absolute left-2 bottom-2 text-[#A3AED0] text-sm font-medium">
          © 2023 Thruways Co. All Rights Reserved.{" "}
        </p>
      </div>
      <SetupSideBar step={1} />
    </div>
  );
};

export default StepOne;
