import React from "react";
import OverviewValueIndicator from "./OverviewValueIndicator";
import AnalyticsCardHeader from "../AnalyticsCard/AnalyticsCardHeader";

const inactiveStyle = {
  backgroundColor: "#f0f0f0", // Grey background
  color: "#c0c0c0", // Grey text
  opacity: 0.5, // Reduced opacity
  pointerEvents: "none", // Disables interactions
};

const OverViewCard = ({
  totalInc,
  cardIcon,
  title,
  desc,
  totalSale,
  isActive,
}) => {
  return (
    <div>
      <div
        className="flex-1 mb-[10px] lg:mr-[15px] bg-[#fff] shadow-[10px_0_60px_0_rgba(226,236,249,0.5)] px-[15px] py-[20px]  rounded-[8px]"
        style={!isActive ? inactiveStyle : {}}
      >
        <AnalyticsCardHeader icon={cardIcon} title={title} />
        <div className="flex justify-between">
          <div className="w-full">
            <p className="mt-[10px] py-[5px] text-[#9197B3] text-[12px] font-normal items-center flex">
              {desc}
            </p>
            <div className="flex justify-between">
              <h2 className="text-[#2A2A2A] text-[25px] font-[700]">
                {totalSale}
              </h2>
              <OverviewValueIndicator value={totalInc} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverViewCard;
